/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/id";
import { Table, Space, Button, Tabs } from "antd";
import { BreadCrumb } from "../../../components";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import {
  formatCurr,
  formatRupiah,
  listAlpha,
  uuidv4,
} from "../../../utils/helper";

// import * as laporanRkamService from "../../../services/v2/planningservice/laporan/rkam";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";
import {
  getLaporanErkam,
  getLaporanErkamIndikatif,
} from "../../../services/v2/planningservice/laporanservices";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import notifAlert from "../../../components/NotifAlert";
import FilterMadrasah from "../Component/FilterMadrasah";
import { setStore } from "../../../redux/actions";
import { getReferenceAll } from "../../../services/v2/referenceservice";
import { getBerkasRencana } from "../../../services/v2/planningservice";
import axios from "axios";
import { planningService } from "../../../services/v2/constant";
import ModalLihatDokumenBos from "../../../components/Modal/ModalLihatDokumenBos";
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";
const { TabPane } = Tabs;
const LaporanRkam = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "RKAM" },
  ];
  const [activeTabKey, setActiveTabKey] = useState<string>("indikatif");
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const groupRole = auth?.group_role || "";
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tmpRefKomponenBiaya, setTmpRefKomponenBiaya] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const [openModalDok, setOpenModalDok] = useState(false);
  const [fileDokumen, setFileDokumen] = useState<any>(null);

  let dataTable: any =
    (tableData.length &&
      tableData.map((item) => {
        return { ...item, key: uuidv4() };
      })) ||
    [];

  let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No Urut",
      key: "noUrut",
      width: 300,
      render: (_, k, i) => i + 1,
    },
    {
      title: "Sumber Dana",
      key: "namaSumberDana",
      dataIndex: "namaSumberDana",
      width: 300,
    },
    {
      title: "Rencana Pendapatan (Rupiah)",
      key: "rencanaPendapatan",
      dataIndex: "rencanaPendapatan",
      width: 300,
      render: (rencanaPendapatan) => (
        <div className="text-right">{formatRupiah(rencanaPendapatan || 0)}</div>
      ),
    },
    {
      title: "Rencana Belanja (Rupiah)",
      key: "rencanaBelanja",
      dataIndex: "rencanaBelanja",
      width: 300,
      render: (rencanaBelanja) => (
        <div className="text-right">{formatRupiah(rencanaBelanja || 0)}</div>
      ),
    },
    {
      title: "Tahap 1",
      key: "jumlahTotal",
      align: "center",
      width: 200,
      render: (record) => <div className="text-right">{formatRupiah(0)}</div>,
    },
    {
      title: "Tahap 2",
      key: "jumlahTotal",
      dataIndex: "jumlahTotal",
      align: "center",
      width: 200,
      render: (record) => <div className="text-right">{formatRupiah(0)}</div>,
    },
  ];

  dataMadrasah?.status === "n" && columns.splice(4, 2);
  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun, activeTabKey]);

  useEffect(() => {
    getDataKomponen(dataMadrasah);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMadrasah]);

  const getData = async (tmpFilter: any) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        let res: any = [];
        if (activeTabKey === "indikatif") {
          res = await getLaporanErkamIndikatif(payload);
        } else {
          res = await getLaporanErkam(payload);
        }
        if (auth?.kode_role === "bkba_pengawas") {
          res = res.filter((item) => item.kodeSumberDana === "ba_rehab_berat");
        }
        setTableData(res || []);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      try {
        if (activeTabKey === "indikatif") {
          const res = await getLaporanErkamIndikatif(payload);
          setTableData(res || []);
        } else {
          const res = await getLaporanErkam(payload);
          setTableData(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const getDataKomponen = async (dataMadrasah: any) => {
    setLoading(true);

    if (auth?.group_role === "madrasah") {
      const refKomponenBiaya = store.komponenBiaya || [];
      if (refKomponenBiaya.length) {
        setTmpRefKomponenBiaya(refKomponenBiaya);
      } else {
        if (dataMadrasah) {
          const komponenBiaya =
            (await getReferenceAll("komponen-biaya", {
              tahun: auth.isTahun,
              kode_provinsi: dataMadrasah?.kode_provinsi,
              kode_kabkota: dataMadrasah?.kode_kabkota,
              status_madrasah: dataMadrasah?.status,
            })) || [];
          setTmpRefKomponenBiaya(komponenBiaya);
          setTimeout(() => {
            dispatch(setStore({ komponenBiaya }));
          }, 100);
        }
      }
    } else {
      if (dataMadrasah) {
        const komponenBiaya =
          (await getReferenceAll("komponen-biaya", {
            tahun: auth.isTahun,
            kode_provinsi: dataMadrasah?.kode_provinsi,
            kode_kabkota: dataMadrasah?.kode_kabkota,
            status_madrasah: dataMadrasah?.status,
          })) || [];
        setTmpRefKomponenBiaya(komponenBiaya);
      }
    }
  };

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
    // getData(values);
  };

  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("data ERKAM");
    ws.mergeCells("A2:G2");
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;
    const headerTitleCell = ws.getCell("A2");
    headerTitleCell.font = {
      size: 14,
      underline: true,
      bold: true,
    };

    headerTitleCell.value = `RENCANA KEGIATAN DAN ANGGARAN MADRASAH (RKAM) KEGIATAN T.A. ${auths?.isTahun}`;
    const listHeaderDetail = [
      {
        title: "Madrasah/PPS",
        value: `: ${dataMadrasah?.nama}`,
      },
      {
        title: "Kecamatan",
        value: `: ${namaKecamatan?.nama || "-"}`,
      },
      {
        title: "Kabupaten / Kota",
        value: `: ${namaKabkota?.nama || "-"}`,
      },
      {
        title: "Provinsi",
        value: `: ${namaProvinsi?.nama || "-"}`,
      },
    ];

    // eslint-disable-next-line array-callback-return
    listHeaderDetail.map((item, i) => {
      const row = i + 4;
      const headerTitleBody = ws.getCell(`A${row}`);
      const headerValueBody = ws.getCell(`B${row}`);
      headerTitleBody.font = {
        size: 12,
        underline: true,
        bold: true,
      };
      headerTitleBody.value = item.title;
      headerValueBody.font = {
        size: 12,
        underline: true,
      };
      headerValueBody.value = item.value;
    });

    // ws.columns = [
    //   { header: "Kode", key: "kode" },
    //   { header: "Uraian", key: "uraian" },
    //   { header: "Volume Output", key: "volumeOutput" },
    //   { header: "Rincian Perhitungan (Jumlah)", key: "rincianPerhitungan" },
    //   { header: "Harga Satuan", key: "hargaSatuan" },
    //   { header: "Jumlah Total", key: "jumlahTotal" },
    // ];
    const rowLast = ws.rowCount;
    let tableTmp: any = [];

    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        let no = 0;
        let subTotal = 0;
        let kodeSumberDana = item.kodeSumberDana;
        let data1: any =
          dataMadrasah?.status === "n"
            ? [(no += 1), "", item.namaSumberDana, "", ""]
            : [(no += 1), "", item.namaSumberDana, "", "", "", ""];

        tableTmp.push(data1);
        item.rencanaKegiatanList.length &&
          // eslint-disable-next-line array-callback-return
          item.rencanaKegiatanList.map((items, j) => {
            let noKeg = j + 1;
            subTotal += items?.rencanaBelanja || 0;
            let data2: any =
              dataMadrasah?.status === "n"
                ? [
                    (no += 1),
                    `${items?.kodeKegiatan}`,
                    "",
                    `${noKeg}. ${items?.namaKegiatan}`,
                    "",
                    "",
                    "",
                    formatCurr(items?.rencanaBelanja),
                  ]
                : [
                    (no += 1),
                    `${items?.kodeKegiatan}`,
                    "",
                    `${noKeg}. ${items?.namaKegiatan}`,
                    "",
                    "",
                    "",
                    formatCurr(items?.rencanaBelanja),
                    formatCurr(
                      items?.kodeSumberDana !== "apbn_bos_semester_2"
                        ? items?.rencanaBelanja
                        : 0,
                    ),
                    formatCurr(
                      items?.kodeSumberDana === "apbn_bos_semester_2"
                        ? items?.rencanaBelanja
                        : 0,
                    ),
                  ];
            tableTmp.push(data2);
            items.rencanaSubKegiatanList.length &&
              // eslint-disable-next-line array-callback-return
              items.rencanaSubKegiatanList.map((itemss) => {
                let data3: any =
                  dataMadrasah?.status === "n"
                    ? [
                        (no += 1),
                        itemss?.kodeSubKegiatan,
                        "",
                        `${listAlpha(j)}.${itemss?.namaSubKegiatan}`,
                        "",
                        "",
                        "",
                        formatCurr(itemss?.rencanaBelanja),
                      ]
                    : [
                        (no += 1),
                        itemss?.kodeSubKegiatan,
                        "",
                        `${listAlpha(j)}.${itemss?.namaSubKegiatan}`,
                        "",
                        "",
                        "",
                        formatCurr(itemss?.rencanaBelanja),
                        formatCurr(
                          itemss?.kodeSumberDana !== "apbn_bos_semester_2"
                            ? itemss?.rencanaBelanja
                            : 0,
                        ),
                        formatCurr(
                          itemss?.kodeSumberDana === "apbn_bos_semester_2"
                            ? itemss?.rencanaBelanja
                            : 0,
                        ),
                      ];
                tableTmp.push(data3);
                itemss.rencanaKegiatanList.length &&
                  // eslint-disable-next-line array-callback-return
                  itemss.rencanaKegiatanList.map((itemsss) => {
                    let checkKomponen: any =
                      tmpRefKomponenBiaya.find(
                        (item: any) =>
                          item.komponen_biaya_harga_id ===
                          itemsss?.komponenBiayaHargaId,
                      ) || null;
                    let data4: any =
                      dataMadrasah?.status === "n"
                        ? [
                            (no += 1),
                            "",
                            "",
                            `> ${checkKomponen?.nama_kategori || "-"}\n>> ${
                              itemsss?.komponenBiayaNama
                            } ${
                              (itemsss.ketBiayaLain &&
                                "| " + itemsss?.ketBiayaLain) ||
                              ""
                            }`,
                            `${itemsss.jumlah} ${itemsss.satuan}`,
                            formatCurr(itemsss.hargaSatuan),
                            itemsss.pajak,
                            formatCurr(itemsss?.totalHarga),
                          ]
                        : [
                            (no += 1),
                            "",
                            "",
                            `> ${itemsss?.komponenBiayaKategori}\n>> ${
                              itemsss?.komponenBiayaNama
                            } ${
                              (itemsss.ketBiayaLain &&
                                "| " + itemsss?.ketBiayaLain) ||
                              ""
                            }`,
                            `${itemsss.jumlah} ${itemsss.satuan}`,
                            formatCurr(itemsss.hargaSatuan),
                            itemsss.pajak,
                            formatCurr(itemsss?.totalHarga),
                            formatCurr(
                              itemsss?.kodeSumberDana !== "apbn_bos_semester_2"
                                ? itemsss?.totalHarga
                                : 0,
                            ),
                            formatCurr(
                              itemsss?.kodeSumberDana === "apbn_bos_semester_2"
                                ? itemsss?.totalHarga
                                : 0,
                            ),
                          ];
                    tableTmp.push(data4);
                  });
              });
          });
        let dataSubTotal =
          dataMadrasah?.status === "n"
            ? ["", "", "Sub Total", "", "", "", "", formatCurr(subTotal)]
            : [
                "",
                "",
                "Sub Total",
                "",
                "",
                "",
                "",
                formatCurr(subTotal),
                formatCurr(
                  kodeSumberDana !== "apbn_bos_semester_2" ? subTotal : 0,
                ),
                formatCurr(
                  kodeSumberDana === "apbn_bos_semester_2" ? subTotal : 0,
                ),
              ];
        tableTmp.push(dataSubTotal);
      });

    ws.addTable({
      name: "MyTable",
      ref: `A${rowLast + 2}`,
      headerRow: true,
      // totalsRow: true,
      style: {
        showFirstColumn: true,
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Kode" },
        { name: "Sumber Dana" },
        { name: "Uraian" },
        { name: "Koefisien" },
        { name: "Harga" },
        { name: "Pajak" },
        { name: "Jumlah" },
        { name: "Tahap I" },
        { name: "Tahap II" },
      ],
      rows: tableTmp,
    });
    let lastLine = 11 + tableTmp.length;
    ws.getCell(`A${lastLine}`).value = "Mengetahui";
    ws.getCell(`H${lastLine}`).value = moment().format("dddd, DD MMMM YYYY");
    ws.getCell(`A${lastLine + 2}`).value = "Ketua Komite Madrasah";
    ws.getCell(`H${lastLine + 2}`).value = "Kepala Madrasah";
    ws.getCell(`A${lastLine + 5}`).value =
      (dataMadrasah?.nama_komite && `( ${dataMadrasah?.nama_komite} )`) ||
      "(.................)";
    ws.getCell(`H${lastLine + 5}`).value =
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
      "(.................)";
    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-eRKAM" + fileExtension);
  };

  function handleExportPdf() {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;

    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    let tableTmp: any = [];

    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        let no = 0;
        let subTotal = 0;
        let kodeSumberDana = item.kodeSumberDana;
        let data1: any =
          dataMadrasah?.status === "n"
            ? [
                (no += 1),
                "",
                item.namaSumberDana,
                { content: "", colSpan: 4 },
                "",
              ]
            : [
                (no += 1),
                "",
                item.namaSumberDana,
                { content: "", colSpan: 4 },
                "",
                "",
                "",
              ];

        tableTmp.push(data1);
        item.rencanaKegiatanList.length &&
          // eslint-disable-next-line array-callback-return
          item.rencanaKegiatanList.map((items, j) => {
            let noKeg = j + 1;
            subTotal += items?.rencanaBelanja || 0;
            let data2: any =
              dataMadrasah?.status === "n"
                ? [
                    (no += 1),
                    `${items?.kodeKegiatan}`,
                    "",
                    { content: `${noKeg}. ${items?.namaKegiatan}`, colSpan: 4 },
                    formatCurr(items?.rencanaBelanja),
                  ]
                : [
                    (no += 1),
                    `${items?.kodeKegiatan}`,
                    "",
                    { content: `${noKeg}. ${items?.namaKegiatan}`, colSpan: 4 },
                    formatCurr(items?.rencanaBelanja),
                    formatCurr(
                      items?.kodeSumberDana !== "apbn_bos_semester_2"
                        ? items?.rencanaBelanja
                        : 0,
                    ),
                    formatCurr(
                      items?.kodeSumberDana === "apbn_bos_semester_2"
                        ? items?.rencanaBelanja
                        : 0,
                    ),
                  ];
            tableTmp.push(data2);
            items.rencanaSubKegiatanList.length &&
              // eslint-disable-next-line array-callback-return
              items.rencanaSubKegiatanList.map((itemss) => {
                let data3: any =
                  dataMadrasah?.status === "n"
                    ? [
                        (no += 1),
                        itemss?.kodeSubKegiatan,
                        "",
                        {
                          content: `${listAlpha(j)}.${itemss?.namaSubKegiatan}`,
                          colSpan: 4,
                        },
                        formatCurr(itemss?.rencanaBelanja),
                      ]
                    : [
                        (no += 1),
                        itemss?.kodeSubKegiatan,
                        "",
                        {
                          content: `${listAlpha(j)}.${itemss?.namaSubKegiatan}`,
                          colSpan: 4,
                        },
                        formatCurr(itemss?.rencanaBelanja),
                        formatCurr(
                          itemss?.kodeSumberDana !== "apbn_bos_semester_2"
                            ? itemss?.rencanaBelanja
                            : 0,
                        ),
                        formatCurr(
                          itemss?.kodeSumberDana === "apbn_bos_semester_2"
                            ? itemss?.rencanaBelanja
                            : 0,
                        ),
                      ];
                tableTmp.push(data3);
                itemss.rencanaKegiatanList.length &&
                  // eslint-disable-next-line array-callback-return
                  itemss.rencanaKegiatanList.map((itemsss) => {
                    let checkKomponen: any =
                      tmpRefKomponenBiaya.find(
                        (item: any) =>
                          item.komponen_biaya_harga_id ===
                          itemsss?.komponenBiayaHargaId,
                      ) || null;
                    let data4: any =
                      dataMadrasah?.status === "n"
                        ? [
                            (no += 1),
                            "",
                            "",
                            `> ${checkKomponen?.nama_kategori || "-"}\n>> ${
                              itemsss?.komponenBiayaNama
                            } ${
                              (itemsss.ketBiayaLain &&
                                "| " + itemsss?.ketBiayaLain) ||
                              ""
                            }`,
                            `${itemsss.jumlah} ${itemsss.satuan}`,
                            formatCurr(itemsss.hargaSatuan),
                            itemsss.pajak,
                            formatCurr(itemsss?.totalHarga),
                          ]
                        : [
                            (no += 1),
                            "",
                            "",
                            `> ${itemsss?.komponenBiayaKategori}\n>> ${
                              itemsss?.komponenBiayaNama
                            } ${
                              (itemsss.ketBiayaLain &&
                                "| " + itemsss?.ketBiayaLain) ||
                              ""
                            }`,
                            `${itemsss.jumlah} ${itemsss.satuan}`,
                            formatCurr(itemsss.hargaSatuan),
                            itemsss.pajak,
                            formatCurr(itemsss?.totalHarga),
                            formatCurr(
                              itemsss?.kodeSumberDana !== "apbn_bos_semester_2"
                                ? itemsss?.totalHarga
                                : 0,
                            ),
                            formatCurr(
                              itemsss?.kodeSumberDana === "apbn_bos_semester_2"
                                ? itemsss?.totalHarga
                                : 0,
                            ),
                          ];
                    tableTmp.push(data4);
                  });
              });
          });
        let dataSubTotal =
          dataMadrasah?.status === "n"
            ? [
                "",
                "",
                { content: "Sub Total", colSpan: 5 },
                formatCurr(subTotal),
              ]
            : [
                "",
                "",
                { content: "Sub Total", colSpan: 5 },
                "",
                formatCurr(
                  kodeSumberDana !== "apbn_bos_semester_2" ? subTotal : 0,
                ),
                formatCurr(
                  kodeSumberDana === "apbn_bos_semester_2" ? subTotal : 0,
                ),
              ];
        tableTmp.push(dataSubTotal);
      });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RENCANA KEGIATAN DAN ANGGARAN MADRASAH (RKAM)`, 150, 12, {
      align: "center",
    });
    doc.text(`TAHUN ANGGARAN ${auths?.isTahun}`, 150, 18, { align: "center" });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Madrasah/PPS", fline, 30);
    doc.text(`: ${dataMadrasah?.nama}`, fline + 60, 30);
    doc.text("NSM", fline, 35);
    doc.text(`: ${dataMadrasah?.nsm}`, fline + 60, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(`: ${namaKecamatan?.nama || "-"}`, fline + 60, 40);
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(`: ${namaKabkota?.nama || "-"}`, fline + 60, 45);
    doc.text("Provinsi", fline, 50);
    doc.text(`: ${namaProvinsi?.nama || "-"}`, fline + 60, 50);

    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles:
        dataMadrasah?.status === "n"
          ? {
              0: { halign: "center" },
              1: { halign: "center" },
              2: { halign: "left" },
              3: { halign: "left" },
              4: { halign: "left" },
              5: { halign: "right" },
              6: { halign: "right" },
              7: { halign: "right" },
            }
          : {
              0: { halign: "center" },
              1: { halign: "center" },
              2: { halign: "left" },
              3: { halign: "left" },
              4: { halign: "left" },
              5: { halign: "right" },
              6: { halign: "right" },
              7: { halign: "right" },
              8: { halign: "right" },
              9: { halign: "right" },
            },
      startY: 55,
      margin: { top: 10, left: 10, right: 10 },
      head:
        dataMadrasah?.status === "n"
          ? [
              [
                { content: "No" },
                { content: "No Kode" },
                { content: "Sumber Dana" },
                { content: "Uraian" },
                { content: "Koefisien" },
                { content: "Harga" },
                { content: "Pajak" },
                { content: "Jumlah (dalam Rp.)" },
              ],
              ["1", "2", "3", "4", "5", "6", "7", "8"],
            ]
          : [
              [
                { content: "No", rowSpan: 2 },
                { content: "No Kode", rowSpan: 2 },
                { content: "Sumber Dana", rowSpan: 2 },
                { content: "Uraian", rowSpan: 2 },
                { content: "Koefisien", rowSpan: 2 },
                { content: "Harga", rowSpan: 2 },
                { content: "Pajak", rowSpan: 2 },
                { content: "Jumlah (dalam Rp.)", rowSpan: 2 },
                { content: "Tahap", colSpan: 2 },
              ],
              ["I", "II"],
              ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            ],
      body: tableTmp,
      didDrawPage: (d) => las.push(d.cursor),
    });

    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Ketua Komite Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_komite && `( ${dataMadrasah?.nama_komite} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    // doc.output("dataurlnewwindow", { filename: "laporan-rkam" });
    var blob = doc.output("blob", { filename: "laporan-rkam" });
    window.open(URL.createObjectURL(blob));

    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  const expandedRowDetail = (data: any) => {
    let dataTableKegiatan: any = data.rencanaKegiatanList.length
      ? data.rencanaKegiatanList
      : [];
    const columnsDetail: any = [
      {
        title: "No Urut",
        key: "no",
        width: 50,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kegiatan",
        key: "namaKegiatan",
        dataIndex: "namaKegiatan",
        width: 250,
      },
      {
        title: "Rencana Belanja (dalam Rp.)",
        key: "rencanaBelanja",
        dataIndex: "rencanaBelanja",
        align: "center",
        width: 180,
        render: (rencanaBelanja) => (
          <div className="text-right">{formatRupiah(rencanaBelanja)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];
    dataMadrasah?.status === "n" && columnsDetail.splice(3, 2);

    return (
      <Table
        columns={columnsDetail}
        dataSource={dataTableKegiatan}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSub(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSub = (dataSub: any) => {
    let dataTableSub: any = dataSub.rencanaSubKegiatanList.length
      ? dataSub.rencanaSubKegiatanList
      : [];
    const columnsDetailSub: any = [
      {
        title: "No Urut",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Sub Kegiatan",
        key: "namaSubKegiatan",
        dataIndex: "namaSubKegiatan",
        width: 250,
      },
      {
        title: "Rencana Belanja (dalam Rp.)",
        key: "rencanaBelanja",
        dataIndex: "rencanaBelanja",
        align: "center",
        width: 180,
        render: (rencanaBelanja) => (
          <div className="text-right">{formatRupiah(rencanaBelanja)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];
    dataMadrasah?.status === "n" && columnsDetailSub.splice(3, 2);
    return (
      <Table
        columns={columnsDetailSub}
        dataSource={dataTableSub}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSubRincian(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSubRincian = (dataSubRincian: any) => {
    let dataTableSub: any = dataSubRincian.rencanaKegiatanList.length
      ? dataSubRincian.rencanaKegiatanList
      : [];
    const columnsDetailSubRincian: any = [
      {
        title: "No Urut",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kategori",
        key: "komponenBiayaKategori",
        // dataIndex: "komponenBiayaKategori",
        width: 250,
        render: (record) => {
          let checkKomponen: any =
            tmpRefKomponenBiaya.find(
              (item: any) =>
                item.komponen_biaya_harga_id === record?.komponenBiayaHargaId,
            ) || null;
          return checkKomponen?.nama_kategori || "-";
        },
      },
      {
        title: "Nama",
        key: "komponenBiayaNama",
        dataIndex: "komponenBiayaNama",
        width: 250,
      },
      {
        title: "Keterangan",
        key: "ketBiayaLain",
        dataIndex: "ketBiayaLain",
        width: 250,
      },
      {
        title: "Dokumen RAB",
        key: "dokumenRAB",
        name: "dokumenRAB",
        width: 160,
        render: (record) => {
          let checkKomponen: any =
            tmpRefKomponenBiaya.find(
              (item: any) =>
                item.komponen_biaya_harga_id === record?.komponenBiayaHargaId,
            ) || null;

          return (
            <>
              {checkKomponen?.jenis_pembelanjaan_id === 5 && (
                <Button onClick={() => handleOpenDokumen(record)}>
                  Lihat Dokumen
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: "Harga Satuan",
        key: "hargaSatuan",
        dataIndex: "hargaSatuan",
        align: "center",
        width: 180,
        render: (hargaSatuan) => (
          <div className="text-right">{formatRupiah(hargaSatuan)}</div>
        ),
      },
      {
        title: "Jumlah",
        key: "jumlah",
        // dataIndex: "jumlah",
        align: "center",
        width: 100,
        render: (record) => `${record.jumlah} ${record.satuan}`,
      },
      {
        title: "Total Harga",
        key: "totalHarga",
        dataIndex: "totalHarga",
        align: "center",
        width: 180,
        render: (totalHarga) => (
          <div className="text-right">{formatRupiah(totalHarga)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.totalHarga
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.totalHarga
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];
    dataMadrasah?.status === "n" && columnsDetailSubRincian.splice(7, 2);
    return (
      <Table
        columns={columnsDetailSubRincian}
        dataSource={dataTableSub}
        pagination={false}
        bordered
      />
    );
  };

  const handleOpenDokumen = async (values: any) => {
    let dokumenRab: any = [];
    try {
      let res: any = await getBerkasRencana(
        values?.rincianRencanaKegiatanId,
        dataMadrasah?.nsm,
        values?.tahun,
      );
      if (res) {
        dokumenRab.push(res);
        getDownloadDokumen(res?.fileName);
      }
    } catch (error) {
      notifAlert({
        type: "error",
        description: "File RAB Tidak ditemukan!!",
      });
      console.log(error);
    }
  };

  const getDownloadDokumen = async (params: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-rencana-definitif/download?fileName=${params}`,
        {
          withCredentials: false,
          responseType: "arraybuffer",

          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + auth?.token,
            "X-Erkam-Year": auth?.isTahun,
          },
        },
      )
      .then(function (response: any) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        setFileDokumen(link);
        setOpenModalDok(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const closeDokument = () => {
    setOpenModalDok(false);
    setFileDokumen(null);
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="RKAM"
        footer={
          <Tabs
            activeKey={activeTabKey}
            size="small"
            onChange={(key) => {
              // setFileList([]);
              // setTmpFilter(null);
              setActiveTabKey(key);
            }}>
            <TabPane tab="Pagu Indikatif" key="indikatif" />
            <TabPane tab="Pagu Definitif" key="definitif" />
          </Tabs>
        }
      />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          <Table
            // rowKey={(record) => record.id}
            columns={columns}
            loading={loading}
            expandable={{
              expandedRowRender: (record) => expandedRowDetail(record),
            }}
            dataSource={dataTable}
            scroll={{ x: "1300" }}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <ModalLihatDokumenBos
        openModalPriview={openModalDok}
        pdfFile={fileDokumen}
        onClose={closeDokument}
      />
    </>
  );
};

export default LaporanRkam;
