/** @format */

export const removeDuplicate = function (arr: any, attr: any, value: any): any {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const getBulan = (bulan: number): string => {
  const daftarBulan = [
    "-",
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  if (bulan > 0 && bulan <= 12) return daftarBulan[bulan];
  else return "-";
};

export const uuidv4 = () => {
  return ([1e7].toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
  );
};

export const getTriwulan = (str) => {
  if (str <= 3) {
    return "Triwulan 1";
  } else if (str <= 6) {
    return "Triwulan 2";
  } else if (str <= 9) {
    return "Triwulan 3";
  } else if (str <= 12) {
    return "Triwulan 4";
  }
};

export const sheet_to_blob = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i <= s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const toTitleCase = (str) => {
  return (
    (str &&
      str
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .replace(/_/g, " ")) ||
    ""
  );
};

export const PembulatanPajak = (nilai, pembulat) => {
  var hasil =
    Math.ceil(parseInt(nilai)) % parseInt(pembulat) === 0
      ? Math.ceil(parseInt(nilai))
      : Math.round(
          (parseInt(nilai) + parseInt(pembulat) / 2) / parseInt(pembulat),
        ) * parseInt(pembulat);
  return hasil;
};

export const getJumlahSumberDana = (id, tmpSumberDana) => {
  return tmpSumberDana
    .filter((item) => item.id.includes(id))
    .map((item) => item.sisa);
};

export const usulanKegiatanStatus = (
  sts: number,
): {
  text: string;
  color: "success" | "danger" | "warning" | "neutral" | "primary";
} => {
  if (sts === 0) return { text: "Menunggu", color: "warning" };
  if (sts === 1) return { text: "Disetujui", color: "success" };
  if (sts === 9) return { text: "Ditolak", color: "danger" };
  return { text: "Menunggu", color: "warning" };
};

export const formatRupiah = (money: number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
};

export const formatRupiahLPJ = (money: number) => {
  if (isNaN(money)) {
    return "Rp 0";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
};

export const formatCurr = (money: number) => {
  return new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  }).format(money);
};

export const isObject = (obj: any) => {
  return obj != null && obj.constructor.name === "Object";
};

export const treeNested = (tmpData: any) => {
  let data: any =
    (tmpData.length &&
      tmpData.map((item: any) => {
        return {
          id: item.id,
          kode: item.kode,
          value: item.kode,
          title: `${item.kode} - ${item.nama}`,
          parent: item.parent,
        };
      })) ||
    [];
  let obj: any = [];
  for (const i in data) {
    let _elem: any = data[i];
    if (_elem.parent) {
      let _parentId: any = _elem.parent;
      if (_parentId === _elem.kode) {
        // check children, if false - add
        if (!_elem.children) {
          _elem.children = [];
        }
        _elem.children.push(_elem);
      } else {
        addChildToParent(_elem, _parentId);
      }
    } // is root
    else {
      obj.push(_elem);
    }
  }

  function addChildToParent(child, parentId) {
    for (const j in data) {
      if (data[j].kode.toString() === parentId.toString()) {
        if (!data[j].children) {
          data[j].children = [];
        }
        data[j].children.push(child);
      }
    }
  }
  return obj;
};

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const treeNestedBelanja = (tmpData: any) => {
  let data: any = tmpData
    .sort((a, b) => a.kode - b.kode)
    .map((item: any) => {
      return {
        kode: item.kode,
        value: item.kode,
        title: `${item.kode} - ${item.nama}`,
        children: item.list_jenis_belanja.length
          ? item.list_jenis_belanja
              .sort((c, d) => d.kode - c.kode)
              .map((itemChild: any) => {
                return {
                  kode: itemChild.kode,
                  value: itemChild.kode,
                  title: `${itemChild.kode} - ${itemChild.nama}`,
                };
              })
          : null,
      };
    });

  return data;
};

export function listAlpha(index: any) {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  return alphabet[index];
}

export const fixedFunding: Array<string> = ["apbn_bantuan_cianjur", "bk_mdl"];
export const listKegiatanCianjurExclude: Array<string> = [
  "6.2.9",
  "6.2.10",
  "6.2.11",
  "6.2.12",
  "6.2.21",
  "6.2.22",
  "6.2.23",
  "6.2.24",
  "6.2.25",
  "6.2.26",
  "6.2.27",
  "6.2.28",
  "6.2.37",
  "6.2.38",
  "6.2.39",
  "6.2.40",
  "6.2.41",
  "6.2.42",
  "6.2.43",
  "6.2.44",
  "6.3.7",
  "6.3.8",
  "6.3.9",
  "6.3.10",
  "3.2.1",
  "3.2.2",
  "3.2.3",
  "6.1.3",
  "6.2.13",
  "6.2.14",
  "6.2.15",
  "6.2.16",
  "6.2.17",
  "6.2.18",
  "6.2.19",
  "6.2.20",
  "6.2.29",
  "6.2.30",
  "6.2.31",
  "6.2.32",
  "6.2.33",
  "6.2.34",
  "6.2.35",
  "6.2.36",
  "6.2.45",
  "6.2.46",
  "6.2.47",
  "6.2.48",
  "6.2.49",
  "6.2.50",
  "6.2.51",
  "6.2.52",
  "6.2.54",
  "6.2.55",
  "6.2.56",
  "6.2.57",
  "6.2.58",
  "6.3.31",
  "6.3.32",
  "6.3.33",
  "6.3.34",
  "6.3.35",
  "6.3.36",
  "6.3.37",
  "6.3.38",
  "6.3.39",
  "6.3.40",
  "6.3.41",
  "6.3.42",
  "6.3.43",
];

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

export const countPersentase = (val1: number, val2: number) => {
  let valueResult: any = isNaN((val1 / val2) * 100)
    ? 0
    : ((val1 / val2) * 100).toFixed(2);
  return (isFinite(valueResult) && 0) || valueResult;
};

export function addFormData(values: any) {
  let fileData: any = new FormData();
  for (let obj in values) {
    if (["files", "file"].includes(obj)) {
      values[obj].length &&
        // eslint-disable-next-line array-callback-return
        values[obj].map((item) => {
          fileData.append(obj, item);
        });
    } else {
      fileData.append(obj, values[obj]);
    }
  }
  return fileData;
}

export function validationBeforeApproval(values: any, valuesHeader: any) {
  let result = true;

  if (values?.biayaSumberDanas?.length) {
    // eslint-disable-next-line array-callback-return
    values?.biayaSumberDanas?.map((item: any) => {
      // item.
    });
  }

  return result;
}

export function statusValidationAction() {
  const result = [
    "Tidak Disetujui",
    "Menunggu",
    "DITOLAK",
    "MENUNGGU",
    "MENUNGGU_DISETUJUI",
  ];
  return result;
}

export function listMenuRealisasi(obj: any) {
  const result: any = {
    pendapatan: "realisasiPendapatan",
    pindah_buku: "realisasiPindahBuku",
    realisasi_kegiatan: "realisasiPengeluaranKegiatan",
    realisasi_pajak: "realisasiPengeluaranPajak",
    setor_pajak: "realisasiPengeluaranPajak",
    pengembalian_dana: "realisasiPengembalianDana",
  };

  return result[obj] || "";
}

export const getBatch = () => {
  let dumpBatch: any = [];
  for (let i = 1; i <= 10; i++) {
    dumpBatch.push({ kode: i, nama: `Batch ${i}` });
  }
  return dumpBatch;
};

export const getJenisKomponenBiaya = () => {
  let jenisList: any = [
    {
      kode: "semester1",
      nama: "Semester 1",
    },
    {
      kode: "semester2",
      nama: "Semester 2",
    },
    {
      kode: "tahunan",
      nama: "Tahunan",
    },
  ];
  return jenisList;
};

export const autoSetAKBbyTipe = (id: any, rencanaKegiatan: any) => {
  let tmpAkb: any = {};
  let startMonth =
    (id === 5 && rencanaKegiatan?.bulan_pelaksanaan_start) ||
    (id === 3 && 7) ||
    1;
  let endMonth =
    (id === 5 && rencanaKegiatan?.bulan_pelaksanaan_end) ||
    (id === 2 && 6) ||
    12;
  let totalMonth =
    (id === 5 &&
      rencanaKegiatan?.bulan_pelaksanaan_end +
        1 -
        rencanaKegiatan?.bulan_pelaksanaan_start) ||
    (id === 4 && 12) ||
    6;

  for (var x = 1; x <= 12; x++) {
    if (startMonth <= x && endMonth >= x) {
      tmpAkb[`jumlahBulan${x}`] = (1 / totalMonth).toFixed(2);
    } else {
      tmpAkb[`jumlahBulan${x}`] = 0;
    }
  }
  return tmpAkb || null;
};

export const checkValidationSaldoRencana = (
  tmpKomponenBiaya: any,
  tmpRekapSumberDana: any,
  dataTable: any,
  tmpValidModalSumberDana: any,
  editingKey: any,
) => {
  if (tmpKomponenBiaya?.listKomponen?.jenis_pembelanjaan_id > 1) {
    let alokasiSumberDana: any = findSumberDana(
      tmpRekapSumberDana,
      tmpKomponenBiaya,
      tmpValidModalSumberDana?.tagSumberDana,
    );
    let tmpEdit: any =
      dataTable?.find((item) => item.id === editingKey) || null;
    let sisaSelisih =
      alokasiSumberDana?.selisih + ((tmpEdit && tmpEdit?.jumlahTotal) || 0);

    if (tmpValidModalSumberDana?.totalSaldo > sisaSelisih) {
      return Promise.reject(
        `Saldo dari Sumber Dana ${alokasiSumberDana?.nama_kode_sumber_dana} tidak mencukupi!`,
      );
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
};

export const koefesienTarget = (listKoefisien: any) => {
  return listKoefisien.koef4Jumlah > 0
    ? listKoefisien.koef4Jumlah *
        listKoefisien.koef3Jumlah *
        listKoefisien.koef2Jumlah *
        listKoefisien.koef1Jumlah
    : listKoefisien.koef3Jumlah > 0
    ? listKoefisien.koef3Jumlah *
      listKoefisien.koef2Jumlah *
      listKoefisien.koef1Jumlah
    : listKoefisien.koef2Jumlah > 0
    ? listKoefisien.koef2Jumlah * listKoefisien.koef1Jumlah
    : listKoefisien.koef1Jumlah > 0
    ? listKoefisien.koef1Jumlah
    : 1;
};

export const createListSumberDana = (alokasi: any) => {
  let tmpSumDana: string = "";
  alokasi.length &&
    // eslint-disable-next-line array-callback-return
    alokasi.map((item: any, i: number) => {
      tmpSumDana +=
        i > 0
          ? `, ${item.rencana_pendapatan.nama_sumber_dana} : ${formatRupiah(
              item.jumlah,
            )}`
          : `${item.rencana_pendapatan.nama_sumber_dana} : ${formatRupiah(
              item.jumlah,
            )}`;
    });
  return tmpSumDana;
};

export const defaultKoefisien = (jenisPembiayaan: number) => {
  let tmpKoefisien = {
    1: {
      koef1Jumlah: 1,
      koef2Jumlah: 0,
      koef3Jumlah: 0,
      koef4Jumlah: 0,
      koef1Satuan: null,
      koef2Satuan: null,
      koef3Satuan: null,
      koef4Satuan: null,
    },
    2: {
      koef1Jumlah: 1,
      koef2Jumlah: 0,
      koef3Jumlah: 0,
      koef4Jumlah: 0,
      koef1Satuan: "semester",
      koef2Satuan: null,
      koef3Satuan: null,
      koef4Satuan: null,
    },
    3: {
      koef1Jumlah: 1,
      koef2Jumlah: 0,
      koef3Jumlah: 0,
      koef4Jumlah: 0,
      koef1Satuan: "semester",
      koef2Satuan: null,
      koef3Satuan: null,
      koef4Satuan: null,
    },
    4: {
      koef1Jumlah: 1,
      koef2Jumlah: 0,
      koef3Jumlah: 0,
      koef4Jumlah: 0,
      koef1Satuan: "tahun",
      koef2Satuan: null,
      koef3Satuan: null,
      koef4Satuan: null,
    },
    5: {
      koef1Jumlah: 1,
      koef2Jumlah: 0,
      koef3Jumlah: 0,
      koef4Jumlah: 0,
      koef1Satuan: "paket",
      koef2Satuan: null,
      koef3Satuan: null,
      koef4Satuan: null,
    },
  };
  return tmpKoefisien[jenisPembiayaan];
};

export const findSumberDana = (
  tmpRekapSumberDana: any,
  tmpTambahKomponen: any,
  tagSumberDana: any,
) => {
  let tmpAlokasi: any = null;
  if (tagSumberDana === "sumberBos") {
    tmpAlokasi =
      tmpRekapSumberDana.find(
        (item: any) =>
          item.kode_sumber_dana ===
          ((tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id === 2 &&
            "apbn_bos_semester_1") ||
            (tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id === 3 &&
              "apbn_bos_semester_2") ||
            (tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id === 4 &&
              "apbn_bos")),
      ) || null;
  } else if (tagSumberDana === "sumberBaRehabBerat") {
    tmpAlokasi =
      tmpRekapSumberDana.find(
        (item: any) => item.kode_sumber_dana === "ba_rehab_berat",
      ) || null;
  } else if (tagSumberDana === "sumberBkba") {
    tmpAlokasi =
      tmpRekapSumberDana.find(
        (item: any) => item.kode_sumber_dana === "bkba_afirmasi",
      ) ||
      tmpRekapSumberDana.find(
        (item: any) => item.kode_sumber_dana === "bkba_kinerja",
      ) ||
      null;
  }

  return tmpAlokasi;
};

export const checkSisaKuantitas = (
  rincian: any,
  tmpKuantitas: any,
  editKuantitas: any,
  tmpKomponenBiaya: any,
) => {
  let tmpSisaKuantitas: any = 0;

  if (tmpKomponenBiaya?.jenis_pembelanjaan_id > 1) {
    let tmpSisa: any = (
      rincian?.totalKuantitas -
      tmpKuantitas +
      editKuantitas
    ).toFixed(4);
    tmpSisaKuantitas = tmpSisa < 0 ? Math.ceil(tmpSisa).toFixed(4) : tmpSisa;
  } else {
    tmpSisaKuantitas = rincian?.totalKuantitas - tmpKuantitas + editKuantitas;
  }
  return tmpSisaKuantitas;
};

// export const checkSisaKuantitas2 = (
//   rincian: any,
//   tmpKuantitas: any,
//   editKuantitas: any,
//   tmpKomponenBiaya: any,
// ) => {
//   let tmpSisaKuantitas: any = 0;

//   if (tmpKomponenBiaya?.jenis_pembelanjaan_id > 1) {
//     let tmpSisaKuantitas2 =
//       1 -
//       parseFloat(
//         ((tmpKuantitas + editKuantitas) / rincian?.hargaSatuan).toFixed(4),
//       );
//     tmpSisaKuantitas = tmpSisaKuantitas2.toFixed(7);
//   } else {
//     tmpSisaKuantitas = rincian?.totalKuantitas - tmpKuantitas + editKuantitas;
//   }
//   return tmpSisaKuantitas;
// };

export const checkSisaSaldoRealisasi = (
  rincian: any,
  data: any,
  hargaSatuan: any,
  tmpKomponenBiaya: any,
  allFilter: any,
) => {
  let tmpSisaSaldoRealisasi: any = 0;

  if (tmpKomponenBiaya?.jenis_pembelanjaan_id > 1) {
    let tmpTotalSaldo = 0;
    let tmpListSumberDanaId: any =
      data?.biayaSumberDanas?.map((item) => item.id) || [];

    allFilter?.totalRealisasi?.length &&
      allFilter?.totalRealisasi
        .filter((item: any) => !tmpListSumberDanaId.includes(item?.id))
        // eslint-disable-next-line array-callback-return
        .map((item: any) => {
          tmpTotalSaldo += item?.jumlah;
        });
    tmpSisaSaldoRealisasi =
      rincian?.hargaSatuan - (tmpTotalSaldo + hargaSatuan);
  } else {
    tmpSisaSaldoRealisasi = rincian?.hargaSatuan;
  }
  return tmpSisaSaldoRealisasi;
};

export const infoKuantitas = (tmpKuantitas: any, tmpKomponenBiaya: any) => {
  return tmpKomponenBiaya?.jenis_pembelanjaan_id > 1
    ? `${tmpKuantitas * 100}%`
    : tmpKuantitas;
};

export const checkRencanaPendapatan = (
  tmpListPendapata: any,
  kodeSumberDana: any,
) => {
  return (
    tmpListPendapata.find((item) => item.kode_sumber_dana === kodeSumberDana) ||
    null
  );
};

export const listKomponenBiayaRehabBerat = () => {
  return [
    "5.7.3",
    "5.7.4",
    "5.5.5",
    "5.6.3",
    "5.6.4",
    "4.4.1.1",
    "4.6.5.71",
    "5.8.3",
    "4.6.10",
  ];
};

export const listKomponenBiayaMDL = () => {
  return [
    "6.7.3.3",
    "6.7.1.1",
    "6.7.1.1",
    "6.7.10.1",
    "7.9.2.1",
    "7.2.1",
    "7.3.3",
    "7.5.1",
    "7.5.3",
    "6.2.6.3",
    "6.6.1.1",
    "6.2.8.3",
    "6.1.6.3",
    "7.7.2",
    "6.2.7.1",
    "4.6.12",
    "5.7.1",
    "5.6.1",
    "5.8.1",
    "5.6.5",
    "5.6.6",
    "4.6.5.73",
    "7.3.4",
    "8.9.1",
    "4.6.13",
    "7.8.13",
    "6.1.6.4",
    "6.7.7.3",
    "11.4.3",
  ];
};
