/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumb } from "../../../components";
import { Table, Space, Tag, Tabs, Modal } from "antd";
import { ExportToExcel } from "../../../components/Export/ExportToExcel";
import {
  ButtonEdit,
  ButtonExport,
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../components/Button";
import InputSearch from "../../../components/InputSearch";
import { formatRupiah } from "../../../utils/helper";
import { getReferenceAll } from "../../../services/v2/referenceservice";
import FilterKomponentBiaya from "./Component/FilterKomponentBiaya";
import notifAlert from "../../../components/NotifAlert";
import { setStore } from "../../../redux/actions";
import ModalSetHarga from "./Component/ModalSetHarga";
import ModalMasterKomponenBiaya from "./Component/ModalMasterKomponenBiaya";
import {
  deleteKomponenBiayaMaster,
  // editKomponenBiaya,
  editKomponenBiayaMaster,
  postKomponenBiaya,
  postKomponenBiayaMaster,
} from "../../../services/v2/referenceservice/komponenbiaya";
import ModalKomponenBiaya from "./Component/ModalKomponenBiaya";
const { TabPane } = Tabs;

const KomponenBiaya = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Referensi" },
    { path: "/", breadcrumbName: "Komponen Biaya" },
  ];

  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const auth = auths?.data || null;
  const profile = store?.profile || null;
  const kodeRole = auth?.kode_role;
  const groupRole = auth?.group_role;
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  const refJenisPembiayaan = store.jenisPembiayaan || [];
  const refJenisBelanja = store.jenisBelanja || [];
  const refKategoriKomponenBiaya = store.kategoriKomponenBiaya || [];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [title, setTitle] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);
  const [searchMaster, setSearchMaster] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [openModalKomponen, setOpenModalKomponen] = useState(false);
  const [editDataKomponen, setEditDataKomponen] = useState<any>(null);
  const [editDataMaster, setEditDataMaster] = useState<any>(null);
  const [openModalMaster, setOpenModalMaster] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("kb1");
  const [statusMadrasah, setStatusMadrasah] = useState<string>("s");
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const tmpProvinsi = store.provinsi || [];
  const tmpKabkota = store.kabkota || [];
  const [tmpRefKomponenBiaya, setTmpRefKomponenBiaya] = useState<any>([]);
  const [refKomponenBiayaMaster, setKomponenBiayaMaster] = useState<any>([]);

  const getData = async (tmpFilter) => {
    setLoading(true);
    const refKomponenBiaya = store.komponenBiaya || [];
    if (groupRole === "madrasah") {
      if (
        refKomponenBiaya.length &&
        refKomponenBiaya[0]?.tahun === auth?.isTahun
      ) {
        setTmpRefKomponenBiaya(refKomponenBiaya);
      } else {
        const komponenBiaya =
          (await getReferenceAll("komponen-biaya", {
            tahun: auth?.isTahun,
            kode_provinsi: profile?.kode_provinsi,
            kode_kabkota: profile?.madrasah.kode_kabkota,
            status_madrasah: profile?.madrasah?.status,
          })) || [];
        setTmpRefKomponenBiaya(komponenBiaya);
        setTimeout(() => {
          dispatch(setStore({ komponenBiaya }));
        }, 100);
      }
    }
    if (groupRole === "kabkota") {
      // if (
      //   refKomponenBiaya.length &&
      //   refKomponenBiaya[0]?.tahun === auth?.isTahun
      // ) {
      //   setTmpRefKomponenBiaya(refKomponenBiaya);
      // } else {
      const payload = {
        tahun: auth?.isTahun,
        kode_provinsi: profile?.kode_provinsi,
        kode_kabkota: profile?.kode_kabkota,
        status_madrasah: statusMadrasah,
      };
      const komponenBiaya = await getReferenceAll("komponen-biaya", payload);
      setTmpRefKomponenBiaya(komponenBiaya || []);
      //   setTimeout(() => {
      //     dispatch(setStore({ komponenBiaya }));
      //   }, 100);
      // }
    }

    if (
      (groupRole === "pusat" || groupRole === "provinsi") &&
      tmpFilter?.kabkota
    ) {
      const komponenBiaya =
        (await getReferenceAll("komponen-biaya", {
          tahun: auth?.isTahun,
          kode_provinsi:
            groupRole === "pusat"
              ? tmpFilter?.provinsi
              : profile?.kode_provinsi,
          kode_kabkota: tmpFilter?.kabkota,
          status_madrasah: statusMadrasah,
        })) || [];

      setTmpRefKomponenBiaya(komponenBiaya);
      // setTimeout(() => {
      //   dispatch(setStore({ komponenBiaya }));
      // }, 100);
      // }
    }
    if (groupRole === "pusat" || groupRole === "provinsi") {
      const komponenBiayaMaster = await getReferenceAll(
        "komponen-biaya-masterlist",
        null,
      );
      setKomponenBiayaMaster(komponenBiayaMaster);
      setTimeout(() => {
        dispatch(setStore({ komponenBiayaMaster }));
      }, 100);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, statusMadrasah]);

  let dataTable: any = search
    ? tmpRefKomponenBiaya.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.tahun !== null &&
            (item.tahun || "").toString().toLowerCase().includes(val)) ||
          (item.nama_kategori !== null &&
            (item.nama_kategori || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.kode !== null &&
            (item.kode || "").toString().toLowerCase().includes(val)) ||
          (item.nama !== null &&
            (item.nama || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpRefKomponenBiaya;

  dataTable = tmpFilter?.kategoriKomponenBiaya
    ? dataTable.filter(
        (item: any) => item.kode_kategori === tmpFilter?.kategoriKomponenBiaya,
      )
    : dataTable;

  dataTable = tmpFilter?.jenisBelanja
    ? dataTable.filter((item: any) =>
        item.jenis_belanja.includes(tmpFilter?.jenisBelanja),
      )
    : dataTable;

  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  // dataTable = dataTable.sort((a, b) => a.kode.localeCompare(b.kode));
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  let dataTableMaster: any = searchMaster
    ? refKomponenBiayaMaster.filter((item: any) => {
        const val = searchMaster.toLowerCase();
        return (
          (item.nama_kategori !== null &&
            (item.nama_kategori || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.kode !== null &&
            (item.kode || "").toString().toLowerCase().includes(val)) ||
          (item.nama !== null &&
            (item.nama || "").toString().toLowerCase().includes(val))
        );
      })
    : refKomponenBiayaMaster;

  dataTableMaster = dataTableMaster.filter(
    (item) => item.status_madrasah === statusMadrasah,
  );

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "Kategori",
      dataIndex: "nama_kategori",
      key: "nama_kategori",
      width: 250,
    },
    {
      title: "Nama",
      key: "nama",
      dataIndex: "nama",
      width: 250,
    },
    {
      title: "Kode",
      key: "kode",
      width: 120,
      dataIndex: "kode",
    },
    {
      title: "Provinsi",
      key: "kode_provinsi",
      width: 250,
      dataIndex: "kode_provinsi",
      render: (kode_provinsi) =>
        tmpProvinsi.length &&
        tmpProvinsi
          .filter((item: any) => item.kode.includes(kode_provinsi))
          .map((item: any) => item.nama),
    },
    {
      title: "Kab / Kota",
      key: "kode_kabkota",
      width: 250,
      dataIndex: "kode_kabkota",
      render: (kode_kabkota) =>
        tmpKabkota.length &&
        tmpKabkota
          .filter((item: any) => item.kode.includes(kode_kabkota))
          .map((item: any) => item.nama),
    },
    {
      title: "Akun Belanja",
      key: "jenis_belanja",
      width: 200,
      dataIndex: "jenis_belanja",
      render: (jenis_belanja) =>
        ((jenis_belanja || jenis_belanja.length) &&
          jenis_belanja.map((item: any) => (
            <Tag key={item} color="blue">
              {item}
            </Tag>
          ))) ||
        "-",
    },
    {
      title: "Spesifikasi",
      key: "spesifikasi",
      width: 250,
      dataIndex: "spesifikasi",
    },
    {
      title: "Satuan",
      key: "satuan",
      width: 180,
      dataIndex: "satuan",
    },
    {
      title: "Status",
      key: "status",
      width: 180,
      dataIndex: "status_madrasah",
    },
    {
      title: "Jenis Pembiayaan",
      key: "jenisPembiayaan",
      width: 180,
      dataIndex: "jenis_pembelanjaan_id",
      render: (jenis_pembelanjaan_id) => {
        let tmpJenisPembiayaan: any =
          refJenisPembiayaan.find(
            (item) => item.id === jenis_pembelanjaan_id,
          ) || null;
        return tmpJenisPembiayaan?.description || "";
      },
    },
    {
      title: "Harga",
      children: [
        {
          title: "Harga 1",
          key: "harga_1",
          width: 180,
          dataIndex: "harga_1",
          render: (harga_1) => (
            <div className="text-right">{formatRupiah(harga_1)}</div>
          ),
        },
        {
          title: "Harga 2",
          key: "harga_2",
          width: 180,
          dataIndex: "harga_2",
          render: (harga_2) => (
            <div className="text-right">{formatRupiah(harga_2)}</div>
          ),
        },
        {
          title: "Harga 3",
          key: "harga_3",
          width: 180,
          dataIndex: "harga_3",
          render: (harga_3) => (
            <div className="text-right">{formatRupiah(harga_3)}</div>
          ),
        },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 100,
      fixed: "right",
      render: (record) => (
        <Space>
          <ButtonTableEdit onClick={() => handleEdit(record)} />
          {/* <ButtonTableDelete
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Yakin Hapus Data?",
                onOk() {
                  return handleDeleteMaster(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          /> */}
        </Space>
      ),
    },
  ];
  kodeRole !== "super_admin_pusat" &&
    kodeRole !== "admin_pusat" &&
    delete columns[9];

  let columnMaster: any = [
    {
      title: "Kategori",
      key: "kode_kategori",
      dataIndex: "kode_kategori",
      width: 250,
      render: (kode_kategori) =>
        refKategoriKomponenBiaya
          .filter((item) => item.kode === kode_kategori)
          .map((item) => item.nama),
    },
    {
      title: "Nama",
      key: "nama",
      dataIndex: "nama",
      width: 250,
    },
    {
      title: "Kode",
      key: "kode",
      width: 120,
      dataIndex: "kode",
    },
    {
      title: "Akun Belanja",
      key: "jenis_belanja",
      width: 120,
      dataIndex: "jenis_belanja",
      render: (jenis_belanja) =>
        ((jenis_belanja || jenis_belanja.length) &&
          jenis_belanja.map((item: any) => (
            <Tag key={item} color="blue">
              {item}
            </Tag>
          ))) ||
        "-",
    },
    {
      title: "Spesifikasi",
      key: "spesifikasi",
      width: 250,
      dataIndex: "spesifikasi",
    },
    {
      title: "Deskripsi",
      key: "deskripsi",
      width: 250,
      dataIndex: "deskripsi",
    },
    {
      title: "Satuan",
      key: "satuan",
      width: 150,
      dataIndex: "satuan",
    },
    {
      title: "Biaya Lain",
      key: "belanja_lain",
      width: 150,
      dataIndex: "belanja_lain",
      render: (belanja_lain) =>
        (belanja_lain && <Tag color="success">Aktif</Tag>) || (
          <Tag color="default">Tidak Aktif</Tag>
        ),
    },
    {
      title: "Jenis Pembiayaan",
      key: "jenisPembiayaan",
      width: 120,
      dataIndex: "jenis_pembelanjaan_id",
      render: (jenis_pembelanjaan_id) => {
        let tmpJenisPembiayaan: any =
          refJenisPembiayaan.find(
            (item) => item.id === jenis_pembelanjaan_id,
          ) || null;
        return tmpJenisPembiayaan?.description || "";
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 100,
      fixed: "right",
      render: (record) => (
        <Space>
          <ButtonTableEdit onClick={() => handleEditMaster(record)} />
          <ButtonTableDelete
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Yakin Hapus Data?",
                onOk() {
                  return handleDeleteMaster(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const handleTambah = () => {
    setTitle("Tambah");
    setEditDataKomponen(null);
    setOpenModalKomponen(true);
    // route.push("komponen-biaya/add");
  };

  const handleTambahMaster = () => {
    setEditDataMaster(null);
    setOpenModalMaster(true);
    setTitle("Tambah");
  };

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
    getData(values);
  };

  const handleEditMaster = (record: any) => {
    setEditDataMaster(record);
    setTitle("Edit");
    setOpenModalMaster(true);
  };

  const handleEdit = (record: any) => {
    setTitle("Edit");
    setEditDataKomponen(record);
    setOpenModalKomponen(true);
  };

  const handleDeleteMaster = async (record: any) => {
    try {
      await deleteKomponenBiayaMaster(record.kode);
      notifAlert({
        type: "success",
        description: "Hapus data komponen biaya berhasil",
      });
      getData(tmpFilter);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveDataMaster = async (values: any) => {
    if (title === "Tambah") {
      try {
        await postKomponenBiayaMaster(values);
        notifAlert({
          type: "success",
          description: "Data berhasil di simpan",
        });
        getData(tmpFilter);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editKomponenBiayaMaster(values);
        notifAlert({
          type: "success",
          description: "Data berhasil di simpan",
        });
        getData(tmpFilter);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSaveDataKomponen = async (values: any) => {
    if (title === "Tambah") {
      try {
        await postKomponenBiaya(values);
        notifAlert({
          type: "success",
          description: "Data berhasil di simpan",
        });
        getData(tmpFilter);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await postKomponenBiaya(values);
        notifAlert({
          type: "success",
          description: "Data berhasil di simpan",
        });
        getData(tmpFilter);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleExport = () => {
    try {
      let xls = tmpRefKomponenBiaya.map((el: any) => {
        let tmpJenisPembiayaan: any =
          refJenisPembiayaan.find(
            (item) => item.id === el.jenis_pembelanjaan_id,
          ) || null;
        return {
          ID: el.komponen_biaya_harga_id,
          Tahun: el.tahun,
          Kategori: el.nama_kategori,
          "Kode Kategori": el.kode_kategori,
          "Nama Kategori":
            refKategoriKomponenBiaya.find(
              (item) => item.kode === el.kode_kategori,
            )?.nama || "",
          "Kode Provinsi": el.kode_provinsi,
          "Kode Kabkota": el.kode_kabkota,
          Kode: el.kode,
          Nama: el.nama,
          Spesifikasi: el.spesifikasi,
          Satuan: el.satuan,
          "Jenis Pembiayaan": tmpJenisPembiayaan?.description || "",
          "Harga 1": el.harga_1,
          "Harga 2": el.harga_2,
          "Harga 3": el.harga_3,
        };
      });
      ExportToExcel(xls, "referensi-komponen-biaya");

      notifAlert({
        type: "success",
        description: "Data berhasil di export",
      });
    } catch (error) {
      notifAlert({
        type: "error",
        description: "Data Gagal di export",
      });
    }
  };

  const handleExportMaster = () => {
    try {
      let xls = refKomponenBiayaMaster.map((el: any) => {
        // let tmpJenisPembiayaan: any =
        //   refJenisPembiayaan.find(
        //     (item) => item.id === el.jenis_pembelanjaan_id,
        //   ) || null;
        let tmpJenisBelanja: any = "";
        let i = 0;
        el?.jenis_belanja.length &&
          // eslint-disable-next-line array-callback-return
          el?.jenis_belanja.map((item: any) => {
            let tJenisBelanja: any =
              refJenisBelanja.find((iitem) => iitem.kode === item) || null;
            if (i === 0) {
              tmpJenisBelanja = `${tJenisBelanja?.kode} - ${tJenisBelanja?.nama} `;
            } else {
              tmpJenisBelanja = `${tmpJenisBelanja} \n ${tJenisBelanja?.kode} - ${tJenisBelanja?.nama} `;
            }
            i++;
          });

        return {
          Kode: el.kode,
          Nama: el.nama,
          "Kode Kategori": el.kode_kategori,
          "Nama Kategori":
            refKategoriKomponenBiaya.find(
              (item) => item.kode === el.kode_kategori,
            )?.nama || "",
          Spesifikasi: el.spesifikasi,
          Satuan: el.satuan,
          // "Jenis Pembiayaan": tmpJenisPembiayaan?.description || "",
          "Jenis Belanja": tmpJenisBelanja,
        };
      });
      ExportToExcel(xls, "referensi-master-komponen-biaya");

      notifAlert({
        type: "success",
        description: "Data berhasil di export",
      });
    } catch (error) {
      notifAlert({
        type: "error",
        description: "Data Gagal di export",
      });
    }
  };
  const handleSetHarga = () => {
    setOpenModal(true);
  };

  const contentList: Record<string, React.ReactNode> = {
    kb1: (
      <>
        <FilterKomponentBiaya handleChangeValues={handleChangeValues} />
        <div className="m-5 p-5 bg-white shadow-md rounded">
          <div className="mb-4 flex justify-end">
            <div className="mr-4">
              <Space size={[8, 8]} wrap>
                {kodeRole === `admin_pusat` && (
                  <ButtonTambah
                    title="Tambah Komponen"
                    onClick={handleTambah}
                  />
                )}
                {kodeRole === `admin_kabkota` && (
                  <ButtonEdit title="Set Harga" onClick={handleSetHarga} />
                )}
                <ButtonExport
                  title="Export"
                  onClick={handleExport}
                  disabled={tmpRefKomponenBiaya.length ? false : true}
                />
              </Space>
            </div>
            <InputSearch
              className="w-3/4"
              onChange={(e: any) => setSearch(e.currentTarget.value)}
            />
          </div>
          <div className="w-full">
            <Table
              rowKey={(record) => record.komponen_biaya_harga_id}
              columns={columns}
              dataSource={dataTable}
              loading={loading}
              scroll={{ x: 1300 }}
              // tableLayout="auto"
              bordered
              pagination={{
                total: totalDataTable,
                position: ["bottomRight"],
                defaultPageSize: pageSize,
                defaultCurrent: page,
                showTotal: (total) => `Total ${total} items`,
                onChange(page, pageSize) {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </div>
      </>
    ),
    kb2: (
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <Tabs
          defaultActiveKey={statusMadrasah}
          size="small"
          onChange={(key) => {
            setStatusMadrasah(key);
          }}>
          <TabPane tab="Swasta" key="s" />
          <TabPane tab="Negeri" key="n" />
        </Tabs>
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              {kodeRole === `admin_pusat` && (
                <ButtonTambah
                  title="Tambah"
                  onClick={handleTambahMaster}
                  // disabled={btnAction}
                />
              )}
              <ButtonExport
                title="Export"
                onClick={() => handleExportMaster()}
              />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearchMaster(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            rowKey={(record) => record.kode}
            columns={columnMaster}
            dataSource={dataTableMaster}
            loading={loading}
            scroll={{ x: 1300 }}
            // tableLayout="auto"
            bordered
          />
        </div>
      </div>
    ),
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Komponen Biaya"
        footer={
          (kodeRole === "admin_pusat" && (
            <Tabs
              defaultActiveKey="kb1"
              size="small"
              onChange={(key) => {
                setActiveTabKey(key);
              }}>
              <TabPane tab="List" key="kb1" />
              <TabPane tab="Master" key="kb2" />
            </Tabs>
          )) ||
          null
        }
      />
      {contentList[activeTabKey]}
      <ModalSetHarga
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        refProvinsi={refProvinsi}
        refKabkota={refKabkota}
      />
      <ModalMasterKomponenBiaya
        openModal={openModalMaster}
        title={title}
        editData={editDataMaster}
        statusMadrasah={statusMadrasah}
        handleSave={handleSaveDataMaster}
        hanldeClose={() => {
          setOpenModalMaster(false);
          setEditDataMaster(null);
        }}
      />
      <ModalKomponenBiaya
        openModal={openModalKomponen}
        title={title}
        editData={editDataKomponen}
        handleSave={handleSaveDataKomponen}
        hanldeClose={() => {
          setOpenModalKomponen(false);
          setEditDataKomponen(null);
        }}
      />
    </>
  );
};

export default KomponenBiaya;
