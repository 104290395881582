/** @format */

import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Modal,
  Divider,
  Descriptions,
  Button,
  Switch,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/id";
import {
  checkSisaKuantitas,
  checkSisaSaldoRealisasi,
  formatRupiah,
  infoKuantitas,
  openInNewTab,
  // PembulatanPajak,
  uuidv4,
} from "../../../../utils/helper";
import { ButtonPlus, ButtonTableDelete } from "../../../../components/Button";
import ModalPenerima, {
  ModalPenerimaRekening,
} from "../../../../components/Modal/ModalPenerima";
import RincianRencana from "./RincianRencana";
import { useSelector } from "react-redux";
import { ButtonUploads } from "../../../../components/Uploads";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";

type FormActionType = {
  openModal: boolean;
  title: string;
  data: any;
  store: any;
  rincian: any;
  tmpKomponenBiaya: any;
  files: any;
  confirmLoading: boolean;
  handleFileUpload: (e: any) => void;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalPengeluaranKegiatan = ({
  openModal,
  title,
  store,
  data,
  rincian,
  tmpKomponenBiaya,
  files,
  confirmLoading,
  handleFileUpload,
  handleSave,
  handleClose,
}: FormActionType) => {
  const auths = useSelector((state: any) => state.auth);
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpTipeKas = store.tipeKas || [];
  const tmpBank = store.bank || [];
  const tmpSumberDana = store.rencanaPendapatanDefinitif || [];
  const tmpPendapatanHeader = store.realisasiPendapatanHeader || null;
  const tmpMetodePembayaran = store.metodePembayaran || [];
  const tmpPajak = store.pajak || [];
  const tmpPenerima = store.penerimaRekening || [];
  const tmpRealisasiBiaya = store.realisasiBiaya || [];
  const [form] = Form.useForm();
  const [buttonEnabled, setButtonEnabled] = useState<any>(true);
  const [buttonEnabledAllokasi, setButtonEnabledAllokasi] = useState<any>(true);
  const [pajakEnabled, setPajakEnabled] = useState<any>(true);

  const [listSumberDana, setListSumberDana] = useState<any>([]);
  const [hargaSatuan, setHargaSatuan] = useState<any>(0);
  const [subTotal, setSubTotal] = useState<any>(0);
  const [totalPajak, setTotalPajak] = useState<any>(0);
  const [totalPajakPengurangan, setTotalPajakPengurangan] = useState<any>(0);
  const [totalPajakTerhutang, setTotalPajakTerhutang] = useState<any>(0);
  const [pajakPPN, setPajakPPN] = useState<any>(0);
  const [disabledRekening, setDisabledRekening] = useState<any>(true);
  const [enabledRekeningPenerima, setEnabledRekeningPenerima] =
    useState<any>(false);
  const [listPenerimaRekening, setListPenerimaRekening] = useState<any>(null);

  const [openModalPenerimaRekening, setOpenModalPenerimaRekening] =
    useState<any>(false);
  const [editKuantitas, setEditKuantitas] = useState<any>(0);
  const [openModalPenerima, setOpenModalPenerima] = useState<any>(false);
  const [validUpload, setValidUpload] = useState<any>([]);
  const [errorUpload, setErrorUpload] = useState<any>(null);
  const [saldoEdit, setSaldoEdit] = useState<any>([]);
  const checkKomponenBiaya: any =
    (tmpKomponenBiaya?.jenis_pembelanjaan_id > 1 && true) || false;
  let allFilter: any = {
    sumberDanaID: [],
    tipeKasID: [],
    rekeningID: [],
    totalRealisasi: [],
    totalRencana: [],
  };

  rincian &&
    // eslint-disable-next-line array-callback-return
    rincian?.alokasiPendapatan.map((items) => {
      items?.rencana_pendapatan?.kode_sumber_dana &&
        allFilter[`sumberDanaID`].push(
          items.rencana_pendapatan.kode_sumber_dana,
        );
      allFilter[`totalRencana`].push({
        rencanaPendapatanId: items.rencana_pendapatan.kode_sumber_dana,
        quantity: items.quantity,
      });
    });

  let tmpListSumberDana: any = [];

  tmpPendapatanHeader?.header3?.length &&
    tmpPendapatanHeader?.header3.map((item) =>
      // eslint-disable-next-line array-callback-return
      item.details.map((items) => {
        let namaSumberDana =
          tmpSumberDana.find(
            (val) => val.kode_sumber_dana === items.sumber_dana,
          ) || null;
        let noRek: any =
          (items.rekening_bank_id &&
            tmpRekeningBelanja.find(
              (val) => val.id === items.rekening_bank_id,
            )) ||
          null;
        let namaTipeKas = tmpTipeKas.find(
          (val) => val.kode === items.kode_tipe_kas,
        );

        let tmpDataSumber: any = {
          id: `${items.sumber_dana}${items.kode_tipe_kas}${items.rekening_bank_id}`,
          idSumberDana: namaSumberDana?.id,
          kodeSumberDana: items.sumber_dana,
          namaSumberDana: namaSumberDana?.nama_sumber_dana,
          kodeTipeKas: items.kode_tipe_kas,
          namaTipeKas: namaTipeKas.nama,
          sisa: items.sisa,
          rekeningBankId: items.rekening_bank_id,
          noRekening: (noRek && noRek.no_rekening) || null,
          namaRekeningBank: (noRek && noRek.nama_bank) || null,
        };
        tmpListSumberDana.push(tmpDataSumber);
      }),
    );

  // let tmpList: any = [];
  let tmpKuantitas = 0;

  tmpRealisasiBiaya.length &&
    tmpRealisasiBiaya
      .filter((item) => item.rencanaRincianKegiatanId.includes(rincian?.id))
      // eslint-disable-next-line array-callback-return
      .map((item) => {
        item?.biayaDetails?.length &&
          item?.biayaDetails.map((items) => (tmpKuantitas += items.kuantitas));
        item?.biayaSumberDanas.length &&
          // eslint-disable-next-line array-callback-return
          item?.biayaSumberDanas.map((items) => {
            allFilter[`totalRealisasi`].push({
              rencanaPendapatanId: items.rencanaPendapatanId,
              quantity: items.quantity,
              jumlah: items.jumlah,
              id: items?.id,
            });
          });
      });

  let filterSumberDana: any = [];

  const tmpList =
    (listSumberDana.length &&
      // eslint-disable-next-line array-callback-return
      listSumberDana.map((item: any) => {
        let tmpSumber =
          (item?.sumberDana &&
            tmpListSumberDana.find((items) => items.id === item?.sumberDana)) ||
          null;

        let tmp = {
          sumberDana: item?.sumberDana || null,
          kodeSumberDana: tmpSumber?.kodeSumberDana || null,
          idSumberDana: tmpSumber?.idSumberDana || null,
          kuantitas: item?.kuantitas || 0,
          pph21: item?.pph21 || 0,
          pph22: item?.pph22 || 0,
          pph23: item?.pph23 || 0,
        };
        tmpKuantitas += item?.kuantitas;
        item?.sumberDana && filterSumberDana.push(item?.sumberDana);
        // tmpList.push(tmp);
        return tmp;
      })) ||
    [];

  let sisaKuantitas = checkSisaKuantitas(
    rincian,
    tmpKuantitas,
    editKuantitas,
    tmpKomponenBiaya,
  );

  const getNameBank = (kodeBank) => {
    const nameBank = tmpBank
      .filter((item) => item.kode === kodeBank)
      .map((item) => item.nama);
    return nameBank;
  };

  const handleChangeField = (values: any) => {
    for (let obj in values) {
      switch (obj) {
        case "sumberDanaList":
          handleCheckSumberDanaList(values);
          break;
        case "hargaSatuan":
          setHargaSatuan((values.hargaSatuan && values.hargaSatuan) || 0);
          handleCheckSumberDanaList(values);
          break;
        case "metodePembayaran":
          setEnabledRekeningPenerima(
            values.metodePembayaran === "transfer" ? true : false,
          );
          break;
        case "penerima":
          setListPenerimaRekening(values.penerima);
          setDisabledRekening(values.penerima ? false : true);
          break;
        // case "potongPajakPPH":
        //   console.log(values.potongPajakPPH);
        //   break;
      }
    }
    checkButtonDisabled();
  };

  const handleChange = (_, values: any) => {
    handleChangeField(values);
  };

  const handleCheckSumberDanaList = (values) => {
    for (let obj in values) {
      if (obj === "sumberDanaList") {
        let tmpListData: any = [];
        values?.sumberDanaList?.length &&
          // eslint-disable-next-line array-callback-return
          values?.sumberDanaList.map((item: any) => {
            tmpListData.push({
              sumberDana: item?.sumberDana,
              kuantitas: checkKomponenBiaya
                ? hitungKuantitas(
                    item?.kuantitas,
                    netHargaSatuan(values?.hargaSatuan),
                    checkKomponenBiaya,
                    rincian,
                  )
                : item?.kuantitas,
            });
          });

        setListSumberDana(tmpListData);
      }
    }
  };

  useEffect(() => {
    if (data && title === "Edit") {
      let editKodeMetode: any = null;
      let editListSumber: any = [];
      let editListSumberAlokasi: any = [];
      let totalEditKuantitas = 0;
      // let totalSaldoEdit = 0;
      data.biayaSumberDanas.length &&
        // eslint-disable-next-line array-callback-return
        data.biayaSumberDanas.map((item) => {
          let tmpEditData: any = {
            sumberDana: `${item.kodeSumberDana}${item.kodeTipeKas}${item.rekeningBankId}`,
            kodeSumberDana: item.kodeSumberDana,
            kuantitas: item.quantity,
            pph21: item.grandPajakTerutangPph21,
            pph22: item.grandPajakTerutangPph22,
            pph23: item.grandPajakTerutangPph23,
          };
          let tmpEditAlokasi: any = {
            editSaldo: item.jumlah,
            id: `${item.kodeSumberDana}${item.kodeTipeKas}${item.rekeningBankId}`,
          };
          editListSumberAlokasi.push(tmpEditAlokasi);
          editKodeMetode = item.kodeMetodePembayaran;
          editListSumber.push(tmpEditData);
          totalEditKuantitas += item.quantity;
          // totalSaldoEdit = item.jumlah;
        });
      setEditKuantitas(totalEditKuantitas);
      setHargaSatuan(data.biayaDetails[0].hargaSatuan);
      setListSumberDana(editListSumber);
      setSaldoEdit(editListSumberAlokasi);

      form.setFieldsValue({
        pajak: data.kodePajak,
        tanggalNota: moment(data.tanggalNota),
        penerima: data.penerimaId,
        keterangan: data.keterangan,
        pemungutPajakPPN: data.biayaDetails[0].pemungutPajakPpn,
        potongPajakPPH: data.biayaDetails[0].pemungutPajakPph,
        hargaSatuan: data.biayaDetails[0].hargaSatuan,
        metodePembayaran: editKodeMetode,
        noRekeningPenerima: data.penerimaRekeningId,
        sumberDanaList: editListSumber,
      });
      checkButtonDisabled();
    }
    setValidUpload(files);

    // // console.log(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, files]);

  const disabledDate = (current) => {
    return current.year() !== moment(`${auths.isTahun}-12-01`).year();
  };

  const handleSaveForm = (values: any) => {
    let dataSumberDanaList: any = [];
    let jumlahTotalKuatantitas: any = 0;
    let grandTotalPPN = 0;
    let grandTotalPPH21 = 0;
    let grandTotalPPH22 = 0;
    let grandTotalPPH23 = 0;
    const dataKomponen: any = rincian || null;
    const dataPajak =
      tmpPajak.find((item) => item.kode === values.pajak) || null;
    const id = title === "Tambah" ? uuidv4() : data?.id;
    const dataMetodePembayaran =
      tmpMetodePembayaran.find(
        (item) => item.kode === values.metodePembayaran,
      ) || null;
    values.sumberDanaList.length &&
      // eslint-disable-next-line array-callback-return
      values.sumberDanaList.map((item: any) => {
        let totalPPN = checkKomponenBiaya
          ? parseFloat(values?.biayaPPN)
          : Math.round(values.biayaPPN * item?.kuantitas);
        let dataTmpList =
          tmpListSumberDana.find((items) => items.id === item.sumberDana) ||
          null;
        let pajakPPH21 = (values.potongPajakPPH && item?.pph21) || 0;
        let pajakPPH22 = (values.potongPajakPPH && item?.pph22) || 0;
        let pajakPPH23 = (values.potongPajakPPH && item?.pph23) || 0;
        let totalJumlah = checkKomponenBiaya
          ? netHargaSatuan(values.hargaSatuan) + totalPPN + pajakPPH22
          : Math.round(netHargaSatuan(values.hargaSatuan) * item?.kuantitas) +
            totalPPN +
            pajakPPH22;
        let ppnTerhutang = values?.pemungutPajakPPN ? totalPPN : 0;
        let listPajakTerhutang = pajakPPH21 + pajakPPH22 + pajakPPH23;
        let grandPajakTerhutang = values?.pemungutPajakPPN
          ? totalPPN + listPajakTerhutang
          : listPajakTerhutang;

        let tmpSumberDanas: any = {
          // id: uuidv4(),
          kodeSumberDana: dataTmpList && dataTmpList?.kodeSumberDana,
          namaSumberDana: dataTmpList && dataTmpList?.namaSumberDana,
          jumlah: totalJumlah,
          quantity: checkKomponenBiaya
            ? hitungKuantitas(
                item?.kuantitas,
                netHargaSatuan(hargaSatuan),
                checkKomponenBiaya,
                rincian,
              )
            : item?.kuantitas,
          kodePajak: dataPajak.kode,
          jenisPajak: dataPajak.deskripsi,
          grandPajak: totalPPN + listPajakTerhutang,
          grandPajakTerutang: grandPajakTerhutang,
          grandPajakTerutangPpn: ppnTerhutang,
          grandPajakTerutangPph21: pajakPPH21,
          grandPajakTerutangPph22: pajakPPH22,
          grandPajakTerutangPph23: pajakPPH23,
          kodeTipeKas: dataTmpList && dataTmpList.kodeTipeKas,
          namaTipeKas: dataTmpList && dataTmpList.namaTipeKas,
          rekeningBankId: dataTmpList && dataTmpList.rekeningBankId,
          rekeningBankNama: dataTmpList && dataTmpList.namaRekeningBank,
          kodeMetodePembayaran: values.metodePembayaran,
          namaMetodePembayaran:
            (dataMetodePembayaran && dataMetodePembayaran.nama) || null,
          rencanaPendapatanId: dataTmpList && dataTmpList.idSumberDana,
        };
        dataSumberDanaList.push(tmpSumberDanas);
        jumlahTotalKuatantitas += checkKomponenBiaya
          ? hitungKuantitas(
              item?.kuantitas,
              netHargaSatuan(hargaSatuan),
              checkKomponenBiaya,
              rincian,
            )
          : item?.kuantitas;
        grandTotalPPN += ppnTerhutang;
        grandTotalPPH21 += pajakPPH21;
        grandTotalPPH22 += pajakPPH22;
        grandTotalPPH23 += pajakPPH23;
      });

    const payload = {
      id: id,
      tahun: auths.isTahun,
      rencanaPendapatanId: null,
      penerimaRekeningId: values?.noRekeningPenerima || null,
      rencanaRincianKegiatanId: rincian?.id,
      penerimaId: values.penerima,
      keterangan: values.keterangan || null,
      tanggalNota: moment(values.tanggalNota).format("YYYY-MM-DD HH:mm:ss"),
      grandSubTotal: subTotal,
      kodePajak: values.pajak,
      jenisPajak: (dataPajak && dataPajak.deskripsi) || null,
      grandPajak: totalPajak,
      grandPajakTerutang: totalPajakTerhutang,
      grandPajakTerutangPpn: grandTotalPPN,
      grandPajakTerutangPph21: grandTotalPPH21,
      grandPajakTerutangPph22: grandTotalPPH22,
      grandPajakTerutangPph23: grandTotalPPH23,
      grandTotal: subTotal + totalPajakPengurangan,
      biayaDetails: [
        {
          realisasiBiayaId: id,
          rencanaRincianKegiatanId: rincian?.id,
          komponenBiayaKategori:
            (dataKomponen && dataKomponen?.komponeBiayaKetegori) || null,
          komponenBiayaNama:
            (dataKomponen && dataKomponen?.komponenBiayaNama) || null,
          komponenBiayaSpesifikasi:
            (dataKomponen && dataKomponen?.komponenBiayaSpesifikasi) || null,
          komponenBiayaSatuan:
            (dataKomponen && dataKomponen?.komponenBiayaSatuan) || null,
          kuantitas: jumlahTotalKuatantitas,
          hargaSatuan: values.hargaSatuan,
          pemungutPajakPpn: values.pemungutPajakPPN ? true : false,
          pemungutPajakPph: values.potongPajakPPH ? true : false,
          pajakPpn:
            (values.pajak === "ppn" && true) ||
            (values.pajak === "ppnTerhutang" && true) ||
            false,
          biayaPpn:
            (values.pajak === "ppn" && values.biayaPPN) ||
            (values.pajak === "ppnTerhutang" && values.biayaPPN) ||
            0,
          biayaPph21: grandTotalPPH21,
          biayaPph22: grandTotalPPH22,
          biayaPph23: grandTotalPPH23,
          grandSubTotal: subTotal,
          grandPajak: totalPajak,
          grandPajakTerutang: totalPajakTerhutang,
          grandTotal: subTotal + totalPajakPengurangan,
        },
      ],
      biayaSumberDanas: dataSumberDanaList,
    };

    handleCloseForm();
    handleSave(payload);
  };

  const handleCloseForm = () => {
    form.resetFields();
    setSaldoEdit([]);
    setListSumberDana([]);
    setHargaSatuan(0);
    setTotalPajak(0);
    setTotalPajakPengurangan(0);
    setTotalPajakTerhutang(0);
    setSubTotal(0);
    setEditKuantitas(0);
    setPajakPPN(0);
    setButtonEnabledAllokasi(true);
    handleClose();
  };

  const checkValidationHargaSatuan = (listSumberDana: any) => {
    let hargaSatuan = form.getFieldValue("hargaSatuan");
    let hargaKomponenBiaya = checkSisaSaldoRealisasi(
      rincian,
      data,
      hargaSatuan,
      tmpKomponenBiaya,
      allFilter,
    );
    let tmpHargaRincian: any = rincian?.hargaSatuan;

    if (checkKomponenBiaya) {
      if (listSumberDana?.length) {
        if (hargaKomponenBiaya < 0) {
          return Promise.reject(
            "Harga satuan melebihi dari harga satuan di rencana!",
          );
        } else {
          return Promise.resolve();
        }
      } else {
        if (hargaSatuan + hargaKomponenBiaya < hargaSatuan) {
          return Promise.reject(
            "Harga satuan melebihi dari harga satuan di rencana!",
          );
        } else {
          return Promise.resolve();
        }
      }
    } else {
      if (hargaSatuan > tmpHargaRincian) {
        return Promise.reject(
          "Harga satuan melebihi dari harga satuan di rencana!",
        );
      } else {
        return Promise.resolve();
      }
    }
  };

  function checkButtonDisabled() {
    const values = form.getFieldsValue();
    setButtonEnabledAllokasi(
      // values.biayaPPN !== undefined &&
      values.pajak && values.hargaSatuan
        ? // values.pajak
          false
        : true,
    );
    if (values.hargaSatuan > 0) {
      let pj: any = tmpPajak.find((item) => item.kode === values.pajak) || null;
      const hargaPajakTmp =
        (values.pajak === "ppn" &&
          Math.round(values.hargaSatuan * (pj.koefisien / 100))) ||
        (values.pajak === "termasukPajak" &&
          Math.round(
            values.hargaSatuan -
              Math.round(values.hargaSatuan * (100 / (pj.koefisien + 100))),
          )) ||
        0;
      form.setFieldsValue({
        biayaPPN: hargaPajakTmp,
      });
      setPajakPPN(hargaPajakTmp);
    }

    setPajakEnabled(values.potongPajakPPH ? false : true);
    setButtonEnabled(values.sumberDanaList.length ? false : true);
    handleSubTotal();
  }

  const handleSubTotal = () => {
    const values = form.getFieldsValue();
    let tmpSubQuantity: any = 0;
    let totalPajakPPhPengurangan = 0;
    let totalPajakPPH = 0;
    values.sumberDanaList.length &&
      // eslint-disable-next-line array-callback-return
      values.sumberDanaList.map((item: any) => {
        tmpSubQuantity += checkKomponenBiaya
          ? hitungKuantitas(
              item?.kuantitas,
              netHargaSatuan(hargaSatuan),
              checkKomponenBiaya,
              rincian,
            )
          : item?.kuantitas;
        if (values?.potongPajakPPH) {
          let pajakPPH21 = item?.pph21 || 0;
          let pajakPPH22 = item?.pph22 || 0;
          let pajakPPH23 = item?.pph23 || 0;
          let tmpPPH = pajakPPH21 + pajakPPH22 + pajakPPH23;
          totalPajakPPH += tmpPPH;
          totalPajakPPhPengurangan += pajakPPH22;
        }
      });

    let tmpSubTotal =
      (checkKomponenBiaya && values.hargaSatuan) ||
      (values.hargaSatuan &&
        Math.round(netHargaSatuan(values.hargaSatuan) * tmpSubQuantity)) ||
      0;
    let totalPPN =
      (values.biayaPPN && Math.round(values.biayaPPN * tmpSubQuantity)) || 0;
    let totalPajak = totalPPN + totalPajakPPH;
    let totalTerhutang = values?.pemungutPajakPPN ? totalPajak : totalPajakPPH;
    setSubTotal(tmpSubTotal);
    setTotalPajak(totalPajak);
    setTotalPajakPengurangan(totalPPN + totalPajakPPhPengurangan);
    setTotalPajakTerhutang(totalTerhutang);
  };

  function netHargaSatuan(harga: any) {
    const values = form.getFieldsValue();
    let pj: any = tmpPajak.find((item) => item.kode === values.pajak) || null;
    const hargaNet =
      (pj &&
        pj.kode === "termasukPajak" &&
        Math.round(harga * (100 / (pj.koefisien + 100)))) ||
      harga;
    return hargaNet;
  }

  function sisaKuantitasSumberDana(
    sumberDanaList: any,
    list: any,
    checkKomponenBiaya: any,
  ) {
    let sisaQty = 0;
    let totalKuantitasRencana = 0;
    let totalKuantitasRealisasi = 0;

    if (list && list?.idSumberDana) {
      let qty = 0;
      sumberDanaList.length &&
        sumberDanaList
          .filter((item) => item.kodeSumberDana === list?.kodeSumberDana)
          // eslint-disable-next-line array-callback-return
          .map((item: any) => {
            qty += item.kuantitas;
          });
      list &&
        allFilter?.totalRencana
          .filter((item) => item.rencanaPendapatanId === list?.kodeSumberDana)
          // eslint-disable-next-line array-callback-return
          .map((item) => {
            totalKuantitasRencana = item.quantity;
          });
      list &&
        allFilter?.totalRealisasi
          .filter((item) => item.rencanaPendapatanId === list.idSumberDana)
          // eslint-disable-next-line array-callback-return
          .map((item) => {
            totalKuantitasRealisasi += item.quantity;
          });
      if (title === "Tambah") {
        sisaQty = totalKuantitasRencana - totalKuantitasRealisasi - qty;
      } else {
        let kuantitasEdit =
          data?.biayaSumberDanas.filter((item) =>
            item.kodeSumberDana.includes(list?.kodeSumberDana),
          ) || [];
        let editQuantity: any = 0;
        kuantitasEdit.length &&
          // eslint-disable-next-line array-callback-return
          kuantitasEdit.map((item) => {
            editQuantity += item.quantity;
          });
        sisaQty =
          totalKuantitasRencana +
          editQuantity -
          (totalKuantitasRealisasi + qty);
      }
    }
    return checkKomponenBiaya
      ? sisaQty < 0
        ? Math.ceil(sisaQty).toFixed(4)
        : sisaQty.toFixed(4)
      : sisaQty;
  }

  const checkFiles = (values: any) => {
    let messageTmp: any = { status: "success", messages: "" };
    if (!values.length) {
      setErrorUpload({
        status: "error",
        messages: "Maaf dokumen tidak boleh kosong!",
      });
      return Promise.reject();
    } else {
      setErrorUpload(messageTmp);
      return Promise.resolve();
    }
  };

  const validationAfterUpload = (values: any) => {
    handleFileUpload(values);
    setValidUpload(values);
    checkFiles(values);
  };

  const hitungKuantitas = (
    listKuantitas: any,
    hargaSatuan: any,
    checkKomponenBiaya: any,
    rincian: any,
  ) => {
    let tmpKuantitas: number = 0;
    if (checkKomponenBiaya) {
      tmpKuantitas = parseFloat(
        (hargaSatuan / rincian?.hargaSatuan).toFixed(4),
      );
    } else {
      tmpKuantitas = listKuantitas?.kuantitas || 0;
    }

    return tmpKuantitas;
  };

  return (
    <>
      <Modal
        open={openModal}
        title={`${title} Nota Pengeluaran Kegiatan`}
        width={1100}
        onCancel={handleCloseForm}
        okButtonProps={{ disabled: buttonEnabled }}
        okText="Simpan"
        confirmLoading={confirmLoading}
        onOk={() => form.submit()}>
        <Form
          form={form}
          key="modalRealisasiPengeluaranKegiatan"
          name="modalRealisasiPengeluaranKegiatan"
          layout="vertical"
          initialValues={{
            biayaPPN: 0,
            sumberDanaList: [],
          }}
          onValuesChange={handleChange}
          onFinish={handleSaveForm}>
          <div className="grid grid-cols-1   md:grid-cols-2 gap-2">
            <div>
              <Form.Item
                label="Tipe Metode Pembayaran"
                name="metodePembayaran"
                rules={[
                  {
                    required: true,
                    message: "Metode Pembayaran tidak boleh kosong!",
                  },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Metode Pembayaran">
                  {tmpMetodePembayaran.length &&
                    tmpMetodePembayaran.map((item: any, i: any) => {
                      return (
                        <Select.Option
                          key={`tipePembayaran${i}`}
                          value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Penerima" required>
                <Input.Group compact>
                  <Form.Item
                    label="Penerima"
                    name="penerima"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Penerima tidak boleh kosong!",
                      },
                    ]}>
                    <Select
                      showSearch
                      style={{ width: "calc(100% - 50px)" }}
                      optionFilterProp="children"
                      placeholder="Pilih Penerima">
                      {tmpPenerima.length &&
                        tmpPenerima
                          // .filter((item) => allFilter.tipeKasID.includes(item.kode))
                          .map((item: any, i: any) => {
                            return (
                              <Select.Option
                                key={`penerima${i}`}
                                value={item.id}>
                                {item.nama}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                  <ButtonPlus
                    type="primary"
                    onClick={() => setOpenModalPenerima(true)}
                  />
                </Input.Group>
              </Form.Item>
            </div>
            {enabledRekeningPenerima && (
              <div>
                <Form.Item label="No Rekening Penerima" required>
                  <Input.Group compact>
                    <Form.Item
                      name="noRekeningPenerima"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "No Rekening Penerima tidak boleh kosong!",
                        },
                      ]}>
                      <Select
                        showSearch
                        style={{ width: "calc(100% - 50px)" }}
                        optionFilterProp="children"
                        placeholder="Pilih No Rekening Penerima">
                        {listPenerimaRekening &&
                          tmpPenerima
                            .filter((item) =>
                              item.id.includes(listPenerimaRekening),
                            )
                            .map((item: any) => {
                              return (
                                item.penerimaRekenings.length &&
                                item.penerimaRekenings.map((items, i) => (
                                  <Select.Option
                                    key={`noRekPenerima${i}`}
                                    value={items.id}
                                    disabled={disabledRekening}>
                                    {getNameBank(items.kode_bank)} -{" "}
                                    {items.no_rekening}
                                  </Select.Option>
                                ))
                              );
                            })}
                      </Select>
                    </Form.Item>
                    <ButtonPlus
                      type="primary"
                      disabled={disabledRekening}
                      onClick={() => setOpenModalPenerimaRekening(true)}
                    />
                  </Input.Group>
                </Form.Item>
              </div>
            )}
            <div>
              <Form.Item
                label="Tanggal Nota"
                name="tanggalNota"
                rules={[
                  {
                    required: true,
                    message: "Tanggal Nota tidak boleh kosong!",
                  },
                ]}>
                <DatePicker disabledDate={disabledDate} showTime />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Keterangan" name="keterangan">
                <Input.TextArea placeholder="Keterangan" />
              </Form.Item>
            </div>
          </div>
          <RincianRencana
            rincian={rincian}
            key="rincian"
            tmpPajak={tmpPajak}
            komponenBiaya={tmpKomponenBiaya}
          />
          <Divider orientation="left">Realisasi</Divider>
          <div className="grid grid-cols-1   md:grid-cols-3 gap-2">
            <div>
              <Form.Item
                label="Harga Satuan"
                name="hargaSatuan"
                rules={[
                  {
                    required: true,
                    message: "Harga Satuan tidak boleh kosong!",
                  },
                  {
                    validator: () => checkValidationHargaSatuan(listSumberDana),
                  },
                ]}>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  prefix="Rp."
                  placeholder="Harga Satuan"
                />
              </Form.Item>
            </div>
            <div>
              <Descriptions layout="vertical" size="small" column={1}>
                <Descriptions.Item label="Sisa Kuantitas">
                  {infoKuantitas(sisaKuantitas, tmpKomponenBiaya)}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div>
              {checkKomponenBiaya && (
                <Descriptions layout="vertical" size="small" column={1}>
                  <Descriptions.Item label="Sisa Saldo">
                    {formatRupiah(
                      checkSisaSaldoRealisasi(
                        rincian,
                        data,
                        hargaSatuan,
                        tmpKomponenBiaya,
                        allFilter,
                      ),
                    )}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1   md:grid-cols-3 gap-2">
            <div>
              <Form.Item
                label="Pajak PPN"
                name="pajak"
                rules={[
                  {
                    required: true,
                    message: "Pajak PPN tidak boleh kosong!",
                  },
                ]}>
                <Select allowClear showSearch placeholder="PPN">
                  {tmpPajak &&
                    tmpPajak
                      .filter((item) => item.rencana === "1")
                      .map((item: any, i) => (
                        <Select.Option key={`ppn${i}`} value={item.kode}>
                          {item.deskripsi}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Pemungut Pajak PPN"
                name="pemungutPajakPPN"
                valuePropName="checked">
                <Switch disabled={checkKomponenBiaya} />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Biaya PPN" name="biayaPPN">
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  placeholder="0"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  prefix="Rp."
                />
              </Form.Item>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <Form.Item
                label="Potong Pajak PPh"
                name="potongPajakPPH"
                valuePropName="checked">
                <Switch disabled={checkKomponenBiaya} />
              </Form.Item>
            </div>
            <div>
              <Descriptions layout="vertical" size="small" column={1}>
                <Descriptions.Item label="Net Harga">
                  {formatRupiah(netHargaSatuan(hargaSatuan))}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>

          <Divider orientation="left">Alokasi Sumber Dana</Divider>
          <Form.List name="sumberDanaList" initialValue={listSumberDana}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index: number) => {
                  let filterSumber =
                    (tmpList[index]?.sumberDana &&
                      filterSumberDana.filter(
                        (item: any) =>
                          !item.includes(tmpList[index]?.sumberDana),
                      )) ||
                    filterSumberDana;
                  let tmpDataSumber =
                    tmpListSumberDana.find(
                      (item) => item.id === tmpList[index]?.sumberDana,
                    ) || null;

                  let sisaSaldoEdit =
                    saldoEdit.find(
                      (item) => item.id === tmpList[index]?.sumberDana,
                    )?.editSaldo || 0;
                  let saldoSumber = (tmpDataSumber && tmpDataSumber.sisa) || 0;

                  let validasiSaldoSumber = saldoSumber + sisaSaldoEdit;
                  let listKuantitas = hitungKuantitas(
                    tmpList[index],
                    netHargaSatuan(hargaSatuan),
                    checkKomponenBiaya,
                    rincian,
                  );

                  let sisaQuantity = sisaKuantitasSumberDana(
                    tmpList,
                    tmpList[index],
                    checkKomponenBiaya,
                  );

                  let totalKuantitasHarga =
                    (checkKomponenBiaya && netHargaSatuan(hargaSatuan)) ||
                    (!isNaN(
                      netHargaSatuan(hargaSatuan) * tmpList[index]?.kuantitas,
                    ) &&
                      netHargaSatuan(hargaSatuan) *
                        tmpList[index]?.kuantitas) ||
                    0;
                  let totalPajakPPN =
                    (!isNaN(pajakPPN * tmpList[index]?.kuantitas) &&
                      pajakPPN * tmpList[index]?.kuantitas) ||
                    0;
                  let totalPajakPPh = pajakEnabled
                    ? 0
                    : tmpList[index]?.pph21 +
                      tmpList[index]?.pph22 +
                      tmpList[index]?.pph23;
                  let totalPajak = totalPajakPPN + totalPajakPPh;
                  let totalPajakPengurangan =
                    totalPajakPPN + tmpList[index]?.pph22;
                  let maxKuantitas = tmpList[index].kuantitas + sisaQuantity;

                  let totalKuantitasHargaPajak =
                    totalKuantitasHarga + totalPajakPengurangan;
                  const checkValidationSaldo = (
                    saldoSumber,
                    totalKuantitasHargaPajak,
                    sisaQuantity,
                  ) => {
                    if (saldoSumber < totalKuantitasHargaPajak) {
                      return Promise.reject(
                        "Saldo dari Sumber Dana tidak mencukupi!",
                      );
                    } else {
                      if (sisaQuantity < 0) {
                        return Promise.reject(
                          "Sisa Kuantitas tidak mencukupi!",
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }
                  };

                  return (
                    <div key={`div1${key}`}>
                      <div
                        key={`div2${key}`}
                        className="flex flex-col md:flex-row gap-2"
                        style={{ marginBottom: 8 }}>
                        <div>{index + 1}.</div>
                        <div className="flex-none">
                          <Form.Item
                            {...restField}
                            label="Sumber Dana"
                            key={`sumberDana${key}`}
                            name={[name, "sumberDana"]}
                            rules={[
                              {
                                required: true,
                                message: "Sumber Dana tidak boleh kosong!",
                              },
                              {
                                validator: () =>
                                  checkValidationSaldo(
                                    validasiSaldoSumber,
                                    totalKuantitasHargaPajak,
                                    sisaQuantity,
                                  ),
                              },
                            ]}>
                            <Select allowClear placeholder="Pilih Sumber Dana">
                              {tmpListSumberDana.length &&
                                tmpListSumberDana
                                  .filter(
                                    (item: any) =>
                                      !filterSumber.includes(item.id),
                                  )
                                  .filter((item: any) =>
                                    allFilter.sumberDanaID.includes(
                                      item.kodeSumberDana,
                                    ),
                                  )
                                  .map((item: any) => (
                                    <Select.Option
                                      key={`sumber${item.id}${key}`}
                                      value={item.id}>
                                      {item.namaSumberDana} | {item.namaTipeKas}{" "}
                                      {item.kodeTipeKas === "rekening_bank" &&
                                        `| ${item.namaRekeningBank}-${item.noRekening}`}
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Form.Item>
                          <div>
                            {/* <Form.Item label="Biaya Pajak PPH"> */}
                            <div className="grid grid-cols-1   md:grid-cols-3 gap-2">
                              <div>
                                <Form.Item
                                  {...restField}
                                  label="PPh 21"
                                  key={`pph21${key}`}
                                  name={[name, "pph21"]}>
                                  <InputNumber
                                    disabled={pajakEnabled}
                                    style={{ width: "100%" }}
                                    min={0}
                                    placeholder="0"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )
                                    }
                                    prefix="Rp."
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  {...restField}
                                  label="PPh 22"
                                  key={`pph22${key}`}
                                  name={[name, "pph22"]}>
                                  <InputNumber
                                    disabled={pajakEnabled}
                                    style={{ width: "100%" }}
                                    min={0}
                                    placeholder="0"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )
                                    }
                                    prefix="Rp."
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  {...restField}
                                  label="PPh 23"
                                  key={`pph23${key}`}
                                  name={[name, "pph23"]}>
                                  <InputNumber
                                    disabled={pajakEnabled}
                                    style={{ width: "100%" }}
                                    min={0}
                                    placeholder="0"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )
                                    }
                                    prefix="Rp."
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            {/* </Form.Item> */}
                          </div>
                        </div>
                        <div className="flex-1">
                          {/* <Form.Item
                            {...restField}
                            label="Kuantitas"
                            key={`kuantitas${key}`}
                            name={[name, "kuantitas"]}
                            rules={[
                              {
                                required: true,
                                message: "Jumlah Kuantitas tidak boleh kosong!",
                              },
                              {
                                validator: () =>
                                  checkValidationSaldo(
                                    validasiSaldoSumber,
                                    totalKuantitasHargaPajak,
                                    sisaQuantity,
                                  ),
                              },
                            ]}>
                            <InputNumber
                              type="number"
                              defaultValue={listKuantitas}
                              readOnly={
                                (tmpKomponenBiaya?.jenis_pembelanjaan_id > 1 &&
                                  true) ||
                                false
                              }
                              max={maxKuantitas}
                              placeholder="Alokasikan Kuantitas"
                            />
                          </Form.Item> */}
                          {(checkKomponenBiaya && (
                            <Descriptions
                              layout="vertical"
                              size="small"
                              column={1}
                              key={`desc${key}`}>
                              <Descriptions.Item label="Kuantitas">
                                {listKuantitas}
                              </Descriptions.Item>
                            </Descriptions>
                          )) || (
                            <Form.Item
                              {...restField}
                              label="Kuantitas"
                              key={`kuantitas${key}`}
                              name={[name, "kuantitas"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Jumlah Kuantitas tidak boleh kosong!",
                                },
                                {
                                  validator: () =>
                                    checkValidationSaldo(
                                      validasiSaldoSumber,
                                      totalKuantitasHargaPajak,
                                      sisaQuantity,
                                    ),
                                },
                              ]}>
                              <InputNumber
                                type="number"
                                // min={1}
                                max={maxKuantitas}
                                placeholder="Alokasikan Kuantitas"
                              />
                            </Form.Item>
                          )}
                        </div>
                        <div className="flex-1">
                          <Descriptions
                            layout="vertical"
                            size="small"
                            column={1}
                            key={`desc${key}`}>
                            <Descriptions.Item label="Sisa Kuantitas">
                              {sisaQuantity}
                            </Descriptions.Item>
                          </Descriptions>
                        </div>
                        <ButtonTableDelete onClick={() => remove(name)} />
                      </div>
                      <div className="mb-2">
                        <Descriptions key={key} size="small" bordered>
                          <Descriptions.Item label="Saldo Sumber Dana" span={3}>
                            <div className="text-right font-extrabold text-lg">
                              {formatRupiah(saldoSumber)}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Jumlah" span={3}>
                            <div className="text-right">
                              {formatRupiah(totalKuantitasHarga)}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Pajak" span={3}>
                            <div className="text-right">
                              {formatRupiah(totalPajak)}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Total" span={3}>
                            <div className="text-right font-extrabold text-lg">
                              {formatRupiah(totalKuantitasHargaPajak)}
                            </div>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  );
                })}
                {sisaKuantitas > 0 && (
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={buttonEnabledAllokasi}
                      onClick={() => add()}
                      icon={<PlusOutlined />}>
                      Tambah
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Divider orientation="left" />

          <Form.Item
            label="Upload Dokumen"
            required
            name="files"
            validateStatus={errorUpload?.status}
            hasFeedback
            extra={
              rincian?.rencanaKegiatan?.tag_sumber_dana ===
                "sumberBaRehabBerat" && (
                <>
                  Download template bukti transaksi Realisasi Rehab Berat,{" "}
                  <Button
                    className="mt-2"
                    onClick={() =>
                      openInNewTab(
                        publicURL + "/doc/BuktiRealisasiBA-RehabBerat.xlsx",
                      )
                    }>
                    Disini
                  </Button>
                </>
              )
            }
            help={errorUpload?.messages}
            rules={[
              {
                validator: () => checkFiles(validUpload),
              },
            ]}>
            <ButtonUploads
              files={files}
              handleFileUpload={validationAfterUpload}
            />
          </Form.Item>
        </Form>
        <Divider />
        <div className="flex justify-start md:justify-end gap-4">
          <div className="md:w-1/2">
            <Descriptions
              column={1}
              contentStyle={{
                fontWeight: 600,
                justifyContent: "end",
                fontSize: 18,
              }}>
              <Descriptions.Item label="Sub Total">
                {formatRupiah(subTotal)}
              </Descriptions.Item>
              <Descriptions.Item label="Total Pajak">
                {formatRupiah(totalPajak)}
              </Descriptions.Item>
              <Descriptions.Item label="Total Pajak Terhutang">
                {formatRupiah(totalPajakTerhutang)}
              </Descriptions.Item>
              <Descriptions.Item label="Grand Total">
                <div className="text-right font-extrabold text-xl">
                  {formatRupiah(subTotal + totalPajakPengurangan)}
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </Modal>
      <ModalPenerima
        openModal={openModalPenerima}
        handleClose={() => setOpenModalPenerima(false)}
      />
      <ModalPenerimaRekening
        openModal={openModalPenerimaRekening}
        penerimaID={listPenerimaRekening}
        handleClose={() => setOpenModalPenerimaRekening(false)}
      />
    </>
  );
};
export default ModalPengeluaranKegiatan;
