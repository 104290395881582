/** @format */

import React, { useState } from "react";
import {
  Select,
  InputNumber,
  Form,
  Table,
  Modal,
  Button,
  TreeSelect,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  formatRupiah,
  listKomponenBiayaMDL,
  listKomponenBiayaRehabBerat,
  treeNested,
  // treeNestedBelanja,
} from "../../../../../utils/helper";
import InputSearch from "../../../../../components/InputSearch";
import { getReferenceAll } from "../../../../../services/v2/referenceservice";
import { setStore } from "../../../../../redux/actions";
import { ButtonUpload } from "../../../../../components/Uploads";
import notifAlert from "../../../../../components/NotifAlert";

type komponenModal = {
  title: string;
  openModal: boolean;
  tipe: any;
  onClose: () => void;
  saveKomponen: (values: any, action: string) => void;
  saveUpload: (values: any) => void;
};

type komponenType = {
  hargaSatuan: any;
  kodeHarga: any;
  listKomponen: any;
  fileRAB: any;
};

const ModalKomponen = (params: komponenModal) => {
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const tmpKategoriKomponenBiaya = store.kategoriKomponenBiaya || [];
  const [errorUpload, setErrorUpload] = useState<any>(null);
  const [form] = Form.useForm();
  const [formUpload] = Form.useForm();
  const [formSetHarga] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [validUpload, setValidUpload] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [tmpHargaSatuan, setTmpHargaSatuan] = useState<any>(0);
  const [tmpFileRAB, setTmpFileRAB] = useState<any>(null);
  const [tmpData, setTmpData] = useState<any>({
    hargaSatuan: 0,
    kodeHarga: "",
    listKomponen: {},
    fileRAB: null,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [kategoriBiaya, setKategoriBiaya] = useState<any>(null);

  const dataSelectKategoriBiaya = treeNested(tmpKategoriKomponenBiaya);
  const [tmpRefKomponenBiaya, setTmpRefKomponenBiaya] = useState<any>([]);
  const tmpListKomponenBiayaRehabBerat = listKomponenBiayaRehabBerat();
  const tmpListKomponenBiayaMDL = listKomponenBiayaMDL();

  const getData = async () => {
    setLoading(true);
    const refKomponenBiaya = store.komponenBiaya || [];
    if (refKomponenBiaya.length) {
      setTmpRefKomponenBiaya(refKomponenBiaya);
    } else {
      const komponenBiaya =
        (await getReferenceAll("komponen-biaya", {
          tahun: auth.isTahun,
          kode_provinsi: auth.madrasah.kode_provinsi,
          kode_kabkota: auth.madrasah.kode_kabkota,
          status_madrasah: auth.madrasah?.status,
        })) || [];
      setTmpRefKomponenBiaya(komponenBiaya);
      setTimeout(() => {
        dispatch(setStore({ komponenBiaya }));
      }, 100);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFiles = (values: any) => {
    let messageTmp: any = { status: "success", messages: "" };
    if (!values.length) {
      setErrorUpload({
        status: "error",
        messages: "Maaf dokumen tidak boleh kosong!",
      });
      return Promise.reject();
    } else {
      setErrorUpload(messageTmp);
      return Promise.resolve();
    }
  };

  const validationAfterUpload = (values: any) => {
    setValidUpload(values);
    checkFiles(values);
  };

  let dataTable: any = search
    ? tmpRefKomponenBiaya.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nama_kategori !== null &&
            (item.nama_kategori || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.kode_komponen_biaya !== null &&
            (item.kode_komponen_biaya || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nama !== null &&
            (item.nama || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpRefKomponenBiaya;

  dataTable = kategoriBiaya
    ? dataTable.filter((item: any) => item.kode_kategori === kategoriBiaya)
    : dataTable;

  dataTable =
    (params?.tipe?.tag_sumber_dana === "sumberBaRehabBerat" &&
      dataTable.filter((item: any) =>
        tmpListKomponenBiayaRehabBerat.includes(item.kode),
      )) ||
    (params?.tipe?.tag_sumber_dana === "sumberBkMDL" &&
      dataTable.filter((item: any) =>
        tmpListKomponenBiayaMDL.includes(item.kode),
      )) ||
    dataTable;
  // dataTable = jenisBelanja
  //   ? dataTable.filter((item: any) => item.jenis_belanja.includes(jenisBelanja))
  //   : dataTable;
  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const handleChange = (values, record) => {
    setAction(values);
    setTmpData(record);
    setTmpHargaSatuan(record[values]);
    if (values === "setHarga" || values === "setLain") {
      setModal(true);
    } else if (record?.jenis_pembelanjaan_id === 5) {
      setOpenUpload(true);
    } else {
      handleFinishKomponen(record[values], values, record, tmpFileRAB);
    }
  };

  const handleSave = (values) => {
    setTmpHargaSatuan(values?.hargaSatuan);
    if (tmpData?.jenis_pembelanjaan_id === 5) {
      setModal(false);
      setOpenUpload(true);
    } else {
      handleFinishKomponen(values?.hargaSatuan, action, tmpData, tmpFileRAB);
    }
  };

  let columns: any = [
    {
      title: "Kode",
      key: "kode",
      dataIndex: "kode",
    },
    {
      title: "Kategori",
      dataIndex: "nama_kategori",
      key: "nama_kategori",
    },
    {
      title: "Nama",
      key: "nama",
      dataIndex: "nama",
    },
    {
      title: "Akun Belanja",
      dataIndex: "jenis_belanja",
      key: "jenis_belanja",
      render: (jenis_belanja) =>
        ((jenis_belanja || jenis_belanja.length) &&
          jenis_belanja.map((item: any, i: number) =>
            i > 0 ? `, ${item}` : item,
          )) ||
        "-",
    },
    {
      title: "Aksi",
      key: "Aksi",
      render: (record) => (
        <Select
          key={record?.komponen_biaya_harga_id}
          value={null}
          placeholder="Pilih Harga"
          onChange={(values) => handleChange(values, record)}>
          <Select.Option value="harga_1">
            {formatRupiah(record.harga_1)}
          </Select.Option>
          <Select.Option value="harga_2">
            {formatRupiah(record.harga_2)}
          </Select.Option>
          <Select.Option value="harga_3">
            {formatRupiah(record.harga_3)}
          </Select.Option>
          <Select.Option value="setHarga">Tetapkan Harga</Select.Option>
        </Select>
      ),
    },
  ];

  const handleSaveUpload = async (values: any) => {
    if (validUpload.length) {
      const res: any = await params.saveUpload(validUpload);
      if (res) {
        setTmpFileRAB(res);
        handleFinishKomponen(tmpHargaSatuan, action, tmpData, res);
      } else {
        notifAlert({
          type: "error",
          description: "Upload Berkas RAB tidak berhasil!!",
        });
      }
    } else {
      notifAlert({
        type: "error",
        description: "File berkas RAB tidak boleh kosong!!",
      });
    }
    // params.saveUpload(values);
  };

  const handleCancelUpload = () => {
    setOpenUpload(false);
    setValidUpload([]);
  };

  const handleReset = () => {
    setModal(false);
    setAction(null);
    setTmpData({
      hargaSatuan: 0,
      kodeHarga: "",
      listKomponen: {},
      fileRAB: null,
    });
    form.resetFields();
    formSetHarga.resetFields();
    formUpload.resetFields();
    setTmpFileRAB(null);
    setTmpHargaSatuan(0);
    setOpenUpload(false);
    setValidUpload([]);
  };

  const handleFinishKomponen = (
    tmpHargaSatuan,
    action,
    tmpData,
    tmpFileRAB,
  ) => {
    let tmpKomponen: komponenType = {
      hargaSatuan: tmpHargaSatuan,
      kodeHarga: action,
      listKomponen: tmpData,
      fileRAB: tmpFileRAB,
    };
    params.saveKomponen(tmpKomponen, params.title);
    handleReset();
  };

  return (
    <>
      <Modal
        open={params.openModal}
        width={1000}
        title={`Komponen Biaya`}
        onCancel={() => params.onClose()}>
        <Form form={form} key="filterKomponenBiaya">
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex-1">
              <Form.Item
                label="Kategori Komponen Biaya"
                name="kategoriKomponenBiaya">
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Kategori Komponen Biaya"
                  allowClear
                  onChange={(values) => setKategoriBiaya(values)}
                  treeData={dataSelectKategoriBiaya}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="w-full md:w-1/2 mb-2">
          <InputSearch
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            size="small"
            columns={columns}
            loading={loading}
            dataSource={dataTable}
            tableLayout="auto"
            bordered
            rowKey={(record) => record.komponen_biaya_harga_id}
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </Modal>
      <Modal
        open={modal}
        title={action === "setHarga" ? `Tetapkan Harga` : "Lain Lain"}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => formSetHarga.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          key="filterSetHarga"
          form={formSetHarga}
          layout="vertical"
          onFinish={handleSave}>
          <Form.Item
            label="Harga Satuan"
            name="hargaSatuan"
            rules={[
              { required: true, message: "Harga Satuan tidak boleh kosong!" },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => {
                let val = `${value}`.replace(/[^a-zA-Z0-9]/g, "");
                return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }}
              prefix="Rp."
              placeholder="Harga Satuan"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={openUpload}
        title={"Upload RAB"}
        onCancel={handleCancelUpload}
        footer={[
          <Button key="back" onClick={handleCancelUpload}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => formUpload.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          key="uploadRAB"
          form={formUpload}
          layout="vertical"
          onFinish={handleSaveUpload}>
          <Form.Item
            label="Upload Dokumen"
            required
            name="files"
            validateStatus={errorUpload?.status}
            hasFeedback
            help={errorUpload?.messages}
            rules={[
              {
                validator: () => checkFiles(validUpload),
              },
            ]}>
            <ButtonUpload
              files={validUpload}
              handleFileUpload={validationAfterUpload}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ModalKomponen;
