/** @format */
import React from "react";

import { useSelector } from "react-redux";

// import ModalValidasiDanaBos from "../../components/Modal/ModalValidasiDanaBos";

const DashboardIndikatif = () => {
  //   const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data;
  //   const isTahun = auths.isTahun;
  // kode_provinsi

  let urlLooker: any =
    (auth?.group_role === "pusat" &&
      "https://lookerstudio.google.com/embed/reporting/43758d49-9d10-4f81-8aba-503a99e7256c/page/toucD") ||
    (auth?.group_role === "provinsi" &&
      `https://lookerstudio.google.com/embed/reporting/248d9502-e27d-44a8-8540-81020f6dcc72/page/toucD/edit?params=%7B"df32":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_provinsi}"%7D`) ||
    (auth?.group_role === "kabkota" &&
      `https://lookerstudio.google.com/embed/reporting/602897d0-7aee-4fe1-b763-c5e73a2e014f/page/toucD/edit?params=%7B"df32":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}"%7D`) ||
    // "https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD";
    (auth?.madrasah?.nsm &&
      `https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD?params=%7B"df40":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.madrasah?.nsm}"%7D`) ||
    "https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD";
  return (
    <>
      {/* <ModalValidasiDanaBos
        dataAuth={auth}
        dataStore={store}
        dataYear={isTahun}
      /> */}
      <div className="p-5 h-screen flex">
        <iframe
          id="looker"
          key="frameLooker"
          title="frameLooker"
          src={urlLooker}
          className="w-full"></iframe>
      </div>
    </>
  );
};

export default DashboardIndikatif;
