/** @format */
export function listDokumen() {
  const dataTableDokumen = [
    {
      key: "docLpj1",
      nama: "Laporan Pertanggung Jawaban (LPJ) Desember 2023",
      kode: 1,
      tahap: 1,
      doc: null, //"/doc/Format_LPJ_Manual.xlsx",
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      key: "docLpj1",
      nama: "Laporan Pertanggung Jawaban (LPJ) Juni 2024 atau bulan selanjutnya",
      kode: 1,
      tahap: 2,
      doc: null, //"/doc/Format_LPJ_Manual.xlsx",
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      key: "docSPPD",
      nama: "Surat Pernyataan Madrasah Tidak Menerima Dana BOS TA. 2023",
      kode: 8,
      tahap: 1,
      doc: "/doc/Lampiran_Bukan_Penerima_Bantuan_2023.docx",
      type: "application/pdf",
    },
    {
      key: "docSPPD",
      nama: "Surat Pernyataan Madrasah Tidak Menerima Dana BOS Tahap 2 TA. 2023",
      kode: 8,
      doc: "/doc/Lampiran_Bukan_Penerima_Bantuan_2023.docx",
      tahap: 2,
      type: "application/pdf",
    },
    {
      key: "docPPDB2",
      nama: "Surat Permohonan Pencairan Dana BOS Tahap 1 TA. 2024",
      kode: 2,
      tahap: 1,
      doc: "/doc/Surat_Permohonan_Pencairan_Dana_BOS_TA_2024.docx",
      type: "application/pdf",
    },
    {
      key: "docPPDB2",
      nama: "Surat Permohonan Pencairan Dana BOS Tahap 2 TA.2024",
      kode: 2,
      tahap: 2,
      doc: "/doc/Surat_Permohonan_Pencairan_Dana_BOS_TA_2024.docx",
      type: "application/pdf",
    },
    {
      key: "docPTJM3",
      nama: "Surat Pernyataan Tanggung Jawab Mutlak",
      kode: 3,
      tahap: 1,
      doc: "/doc/Formulir_SPTJM_BOS_2024.docx",
      type: "application/pdf",
    },
    {
      key: "docPTJM3",
      nama: "Surat Pernyataan Tanggung Jawab Mutlak",
      kode: 3,
      tahap: 2,
      doc: "/doc/Formulir_SPTJM_BOS_2024.docx",
      type: "application/pdf",
    },
    // {
    //   key: "docPTJB4",
    //   nama: "Surat Pernyataan Tanggung Jawab Belanja",
    //   kode: 4,
    //   tahap: 2,
    //   doc: null,
    // },
    {
      key: "docPPK5",
      nama: "Surat Perjanjian Kerjasama",
      kode: 4,
      tahap: 1,
      doc: null,
      type: "application/pdf",
    },
    {
      key: "docPPK5",
      nama: "Surat Perjanjian Kerjasama",
      kode: 4,
      tahap: 2,
      doc: null,
      type: "application/pdf",
    },
    {
      key: "docRKAM6",
      nama: "Rencana Kerja Anggaran Madrasah (RKAM)",
      kode: 5,
      tahap: 1,
      doc: null,
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      key: "docRKAM6",
      nama: "Rencana Kerja Anggaran Madrasah (RKAM)",
      kode: 5,
      tahap: 2,
      doc: null,
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      key: "docKPBT7",
      nama: "Kuitansi Penerimaan Bantuan Tahap 1 TA. 2024",
      kode: 6,
      tahap: 1,
      doc: "/doc/Kuitansi_Penerimaan_BOS_2024_1.docx",
      type: "application/pdf",
    },
    {
      key: "docKPBT7",
      nama: "Kuitansi Penerimaan Bantuan Tahap 2 TA. 2024",
      kode: 6,
      tahap: 2,
      doc: "/doc/Kuitansi_Penerimaan_BOS_2024_1.docx",
      type: "application/pdf",
    },
    {
      key: "docBPD",
      nama: "Bukti Pengembalian Dana Tahap 2 TA. 2023",
      kode: 7,
      tahap: 1,
      doc: "/doc/Kuitansi_Penerimaan_BOS_2024.docx",
      type: "application/pdf",
    },
    // {
    //   key: "docBPD",
    //   nama: "Bukti Pengembalian Dana Tahap 2 TA. 2023",
    //   kode: 7,
    //   tahap: 2,
    //   doc: "/doc/Kuitansi_Penerimaan_BOS_2024.docx",
    //   type: "application/pdf",
    // },
    {
      key: "docSPTJB",
      nama: "Surat Pernyataan Tanggung Jawab Belanja Kepala Madrasah dan PPK",
      kode: 11,
      tahap: 2,
      doc: "/doc/BOS-SPTJB.docx",
      type: "application/pdf",
    },
    {
      key: "docBAPE",
      nama: "Dokumen BAP BOS 30 September 2023",
      kode: 10,
      tahap: 1,
      doc: null,
      type: "application/pdf",
    },
    // {
    //   key: "docBAPE",
    //   nama: "Dokumen BAP BOS 30 Juni 2024",
    //   kode: 10,
    //   tahap: 2,
    //   doc: null,
    //   type: "application/pdf",
    // },
    {
      key: "docTBUP",
      nama: `Tanda Bukti Upload Pencairan Dana BOS TA. 2024`,
      kode: 9,
      tahap: 1,
      doc: null,
    },
    {
      key: "docTBUP",
      nama: "Tanda Bukti Upload Pencairan Dana BOS TA. 2024",
      kode: 9,
      tahap: 2,
      doc: null,
    },
  ];
  return dataTableDokumen;
}

export const validasiListDokumen = (type: any, tahap: any, penerima: any) => {
  if (type === "R") {
    const listDoc: any = [
      {
        list: [2, 3, 4, 7, 6, 10],
        tahap: 1,
        penerimaBos: 1,
      },
      {
        list: [8, 2, 3, 4, 7, 6, 10],
        tahap: 1,
        penerimaBos: 0,
      },
      {
        list: [2, 6, 11],
        tahap: 2,
        penerimaBos: 1,
      },
      {
        list: [8, 2, 11, 6],
        tahap: 2,
        penerimaBos: 0,
      },
      {
        list: [2, 3, 4, 6],
        tahap: 2,
        penerimaBos: 2,
      },
      {
        list: [8, 2, 3, 4, 6],
        tahap: 2,
        penerimaBos: 3,
      },
    ];

    let dataList: any =
      listDoc.find(
        (item) => item.tahap === tahap && item.penerimaBos === penerima,
      ) || null;

    return dataList?.list;
  } else {
    const listdocManual: any = [
      {
        list: [1, 2, 3, 4, 5, 6, 10],
        tahap: 1,
        penerimaBos: 1,
      },
      {
        list: [8, 2, 3, 4, 5, 6, 10],
        tahap: 1,
        penerimaBos: 0,
      },
      {
        list: [1, 2, 5, 6, 11],
        tahap: 2,
        penerimaBos: 1,
      },
      {
        list: [8, 2, 5, 6, 11],
        tahap: 2,
        penerimaBos: 0,
      },
      {
        list: [1, 2, 3, 4, 5, 6],
        tahap: 2,
        penerimaBos: 2,
      },
      {
        list: [2, 3, 4, 5, 6, 8],
        tahap: 2,
        penerimaBos: 3,
      },
    ];
    let dataList: any =
      listdocManual.find(
        (item: any) =>
          item.tahap.toString() === tahap &&
          item.penerimaBos.toString() === penerima,
      ) || null;
    return dataList?.list;
  }

  // return listdoc;
};

export const getTanggalPengajuanBos = () => {
  const listJadwal: any = [
    {
      startDate: "2024-01-15 08:00:00",
      endDate: "2024-01-23 17:00:00",
      tahap: 1,
      batch: 1,
    },
    {
      startDate: "2024-01-27 00:00:00",
      endDate: "2024-02-08 17:00:00",
      tahap: 1,
      batch: 2,
    },
    {
      startDate: "2024-02-15 08:00:00",
      endDate: "2024-02-20 17:00:00",
      tahap: 1,
      batch: 3,
    },
    {
      startDate: "2024-02-24 08:00:00",
      endDate: "2024-02-29 17:00:00",
      tahap: 1,
      batch: 4,
    },
    {
      startDate: "2024-04-23 08:00:00",
      endDate: "2024-05-12 17:00:00",
      tahap: 1,
      batch: 5,
    },
    {
      startDate: "2024-05-22 08:00:00",
      endDate: "2024-05-30 17:00:00",
      tahap: 1,
      batch: 6,
    },
    {
      startDate: "2024-08-13 08:00:00",
      endDate: "2024-08-22 17:00:00",
      tahap: 2,
      batch: 1,
    },
    {
      startDate: "2024-08-30 08:00:00",
      endDate: "2024-09-08 17:00:00",
      tahap: 2,
      batch: 2,
    },
    {
      startDate: "2024-09-15 08:00:00",
      endDate: "2024-09-22 17:00:00",
      tahap: 2,
      batch: 3,
    },
    {
      startDate: "2024-10-01 08:00:00",
      endDate: "2024-10-21 17:00:00",
      tahap: 2,
      batch: 4,
    },
    {
      startDate: "2024-11-05 08:00:00",
      endDate: "2024-12-31 17:00:00",
      tahap: 2,
      batch: 5,
    },
  ];
  return listJadwal;
};

export const getStatusDokumen = (status: any) => {
  let statusDokumen: any = [
    {
      status: {
        color: "",
        infoMessage: "Ajukan Dokumen",
        toolstips:
          "Lengkapi kelengkapan dokumen lalu ajukan untuk proses verifikasi",
      },
      kode: 0,
    },
    {
      status: {
        color: "red",
        infoMessage: "Revisi Dokumen",
        toolstips:
          "Revisi dokumen yang di tolak oleh verifikator, lalu ajukan kembali untuk proses verifikasi",
      },
      kode: 1,
    },
    {
      status: {
        color: "blue",
        infoMessage: "Verifikasi",
        toolstips:
          "Proses Dokumen sedang di verifikasi oleh verifikator kabkota/provinsi",
      },
      kode: 2,
    },
    {
      status: {
        color: "blue",
        infoMessage: "Disetujui Verifikator",
        toolstips:
          "Proses Dokumen berhasil disetujui dan diajukan ke team pusat",
      },
      kode: 3,
    },
    {
      status: {
        color: "blue",
        infoMessage: "Pengajuan",
        toolstips: "Proses Dokumen sedang diajukan oleh pusat ke pihak Bank",
      },
      kode: 4,
    },
    {
      status: {
        color: "blue",
        infoMessage: "Penyaluran",
        toolstips:
          "Proses pihak Bank melakukan penyaluran alokasi dana ke rekening Lembaga",
      },
      kode: 5,
    },
    {
      status: {
        color: "green",
        infoMessage: "Pencairan",
        toolstips:
          "Lembaga sudah bisa mencairkan dana dengan cara mendownload bukti pencairan",
      },
      kode: 6,
    },
    {
      status: {
        color: "green",
        infoMessage: "DiCairkan",
        toolstips: "Lembaga sudah mencairkan dana alokasi",
      },
      kode: 7,
    },
  ];
  return statusDokumen.find((item) => item.kode === status) || null;
};

export const checkDataErkam = (dataLapErkam: any, tmpSpesialBOSTahap2: any) => {
  let result = true;

  let dataTahap1: any =
    (dataLapErkam.length &&
      dataLapErkam.find(
        (item: any) => item?.kodeSumberDana === "apbn_bos_semester_1",
      )) ||
    null;
  let dataTahap2: any =
    (dataLapErkam.length &&
      dataLapErkam.find(
        (item: any) => item?.kodeSumberDana === "apbn_bos_semester_2",
      )) ||
    null;
  if (tmpSpesialBOSTahap2) {
    if (!dataTahap2) {
      result = true;
    } else if (dataTahap2?.rencanaPendapatan !== dataTahap2?.rencanaBelanja) {
      result = true;
    } else {
      result = false;
    }
  } else {
    if (!dataTahap1 || !dataTahap2) {
      result = true;
    } else if (dataTahap1?.rencanaPendapatan !== dataTahap1?.rencanaBelanja) {
      result = true;
    } else if (dataTahap2?.rencanaPendapatan !== dataTahap2?.rencanaBelanja) {
      result = true;
    } else {
      result = false;
    }
  }

  return result;
};

export const checkDataLpj = (
  dataRealisasi: any,
  dataSumber: any,
  checkDataMadrasah: any,
) => {
  let notifLpj: any = null;

  if (
    dataRealisasi &&
    dataSumber?.kode_sumber_dana === "apbn_bos_semester_2" &&
    !checkDataMadrasah
  ) {
    let tmpSumberRealisasi: any = [];
    dataRealisasi?.header3.length &&
      // eslint-disable-next-line array-callback-return
      dataRealisasi?.header3.map((item: any) => {
        item?.details.length &&
          item?.details
            .filter((iitem) => iitem.sumber_dana === "apbn_bos_semester_1")
            .map((iitem) => tmpSumberRealisasi.push(iitem));
      });
    let tmpPendapatan = 0;
    let tmpPengeluaran = 0;
    tmpSumberRealisasi.length &&
      // eslint-disable-next-line array-callback-return
      tmpSumberRealisasi.map((item) => {
        tmpPendapatan += item.pendapatan;
        tmpPengeluaran += item.pengeluaran;
      });
    let persen = Math.round((tmpPengeluaran / tmpPendapatan) * 100);

    if (persen <= 80) {
      notifLpj = `Transaksi realisasi untuk tahap 1 masih ${persen}%`;
    } else if (isNaN(persen)) {
      notifLpj = "Madrasah belum melakukan transaksi realisasi";
    }
  }

  return notifLpj;
};

export const checkPenerimaBOSID = (status: any) => {
  return (status === 4 && 0) || status;
};

export const checkFileDok = (
  fileList: any,
  kode: any,
  tahap: any,
  statusBos: any,
) => {
  return (
    fileList.find(
      (item) =>
        item.kodeDok === kode &&
        item.tahap === tahap &&
        item.penerimaBos === checkPenerimaBOSID(statusBos),
    ) || null
  );
};

export const spesialBOSPengajuan = () => {
  return [
    "121211730016",
    "111236740014",
    "121232120062",
    "111233180024",
    "111233060017",
    "111233760008",
    "131262030023",
    "111262710013",
    "121265710005",
    "111272070005",
    "131271110002",
    "131216030037",
  ];
};

export const spesialBOSTahap2 = () => {
  return [
    "111236030065",
    "131233180066",
    "131235070076",
    "111235090429",
    "111235090434",
    "121235090143",
    "121235090247",
    "121235100058",
    "111235110047",
    "131235130124",
    "111235150250",
    "121235160095",
    "121235170124",
    "131235220046",
    "111235220237",
    "111235220268",
    "111235240323",
    "131235270061",
    "131235270069",
    "131235270239",
    "111235270265",
    "111235270333",
    "111235270445",
    "121235270135",
    "121235270169",
    "111235710006",
    "121262030047",
    "121252050018",
    "111272030021",
    "121236020120",
    "121236030138",
    "111233010176",
    "121233010049",
    "121233050048",
    "111233100069",
    "121233280006",
    "111235070366",
    "131261040005",
    "121262010013",
    "131262030013",
    "121264020056",
    "111282010002",
    "131282040004",
    "111252050010",
    "131276020042",
    "111276020033",
    "121276020058",
    "121272010017",
    "111272030017",
    "121272040018",
    "111272050009",
    "111274010025",
    "121274080002",
    "131213100009",
    "121216020077",
    "121212220048",
    "121211070010",
    "131217710003",
    "131215020015",
    "111215020002",
    "121215020014",
    "121215020023",
    "121215020034",
    "131215030009",
    "131215030015",
    "121215030008",
    "121215030030",
    "121215030038",
    "131215070021",
    "131215070024",
    "121215070028",
    "111215080014",
    "111215710004",
    "121233120015",
    "121235030016",
    "111235090333",
    "131235100015",
    "131235100018",
    "131235110073",
    "131235110074",
    "111235110120",
    "121235110118",
    "121235110146",
    "111235240386",
    "111235240442",
    "121235240173",
    "121235240186",
    "121235290110",
    "131261010012",
    "131263040012",
    "121263060008",
    "131263100008",
    "131264070002",
    "131264080002",
    "121282040012",
    "131252010076",
    "121252010065",
    "131252020045",
    "111252020081",
    "121252020047",
    "121252020230",
    "111252050047",
    "131252720009",
    "131214010056",
    "131214070017",
    "131273060043",
    "111272050014",
    "121272080040",
    "111271070011",
    "121232050301",
    "111262100003",
    "121262060008",
    "111262120004",
    "131282040013",
    "131274720009",
    "121211060020",
    "121217050003",
    "111232050089",
    "131232120020",
    "111232120095",
    "121232120057",
    "121232120065",
    "131233210064",
    "111235730011",
    "131221710014",
    "121272040022",
    "111272080033",
    "111272110003",
    "121272710008",
    "121272710021",
    "121274060017",
    "121274090007",
    "121274100008",
    "111274710018",
    "111212070077",
    "111212740014",
    "111212040006",
    "131211050006",
    "121211160001",
    "111281010029",
    "131281020001",
    "131281030003",
    "111281050013",
    "121281050006",
    "121281050010",
    "121281070006",
    "121214080042",
    "121214090026",
    "121213040003",
    "121213050017",
    "131216020114",
    "121216020053",
    "111221010004",
    "121232140051",
    "121232050182",
    "111264030003",
    "121252010123",
    "131252030164",
    "121212060007",
  ];
};
