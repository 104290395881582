/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table } from "antd";
import { BreadCrumb } from "../../../components";
import { ButtonDropdownExport } from "../../../components/Button";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import notifAlert from "../../../components/NotifAlert";
import InputSearch from "../../../components/InputSearch";
import { formatCurr } from "../../../utils/helper";
import { getRealizationAll } from "../../../services/v2/realizationservice";

const RealisasiPengadaan = () => {
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;

  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Manajemen Asset" },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.nama_madrasah !== null &&
            (item.nama_madrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;

  const getData = async () => {
    setLoading(true);
    try {
      const tmpManagementAsset =
        (await getRealizationAll(`management-asset/realisasi`, {
          tahun: auth?.isTahun,
          madrasahId: auth?.madrasah?.id,
        })) || [];
      setTmpMadrasah(tmpManagementAsset);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:G1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 40;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 30;
    worksheet.getColumn("F").width = 30;
    worksheet.getColumn("G").width = 30;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `REALISASI PENGADAAN BARANG TAHUN ${auth.isTahun} `;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.namaKomponenBiaya,
        e.namaJenisBelanja,
        e.kodeAkun,
        e.realisasiKuantitas,
        e.komponenBiayaSatuan,
        formatCurr(e.realisasiBelanja),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Komponen Biaya" },
        { name: "Jenis Belanja" },
        { name: "Kode Akun" },
        { name: "Jumlah" },
        { name: "Satuan" },
        { name: "Total" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Realisasi-pengadaan-barang${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(`REALISASI PENGADAAN BARANG TAHUN ${auth.isTahun}`, 150, 10, {
      align: "center",
    });

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "komponenBiaya",
        dataKey: "komponenBiaya",
        padding: 0,
        header: "Komponen Biaya",
        width: 100,
      },
      {
        id: "namaJenisBelanja",
        dataKey: "namaJenisBelanja",
        padding: 0,
        header: "Jenis Belanja",
        width: 50,
      },
      {
        id: "kodeAkun",
        dataKey: "kodeAkun",
        padding: 0,
        header: "Kode Akun",
        width: 50,
      },
      {
        id: "jumlahUnit",
        dataKey: "jumlahUnit",
        padding: 0,
        header: "Jumlah",
        width: 40,
      },
      {
        id: "satuan",
        dataKey: "satuan",
        padding: 0,
        header: "Satuan",
        width: 30,
      },

      {
        id: "total",
        dataKey: "total",
        padding: 0,
        header: "Total",
        width: 50,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 20 },
        1: { halign: "center", cellWidth: 60 },
        2: { halign: "center", cellWidth: 60 },
        3: { halign: "center", cellWidth: 23 },
        4: { halign: "center", cellWidth: 24 },
        5: { halign: "center", cellWidth: 25 },
        6: { halign: "right", cellWidth: 43 },
      },
      startY: 25,
      margin: { top: 4, left: 5, right: 4 },
      columns: headers,
      body: tmpMadrasah.map((e, i) => {
        return {
          id: i + 1,
          komponenBiaya: e.namaKomponenBiaya,
          namaJenisBelanja: e.namaJenisBelanja,
          kodeAkun: e.kodeAkun,
          jumlahUnit: e.realisasiKuantitas,
          satuan: e.komponenBiayaSatuan,
          total: formatCurr(e.realisasiBelanja),
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "realisasi-pengadaan-barang" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  let columns: any = [
    {
      title: "Komponen Biaya",
      key: "komponenBiaya",
      width: 230,
      dataIndex: "namaKomponenBiaya",
    },
    {
      title: "Jenis Belanja",
      width: 230,
      dataIndex: "namaJenisBelanja",
      key: "namaJenisBelanja",
    },
    {
      title: "Kode Akun",
      dataIndex: "kodeAkun",
      key: "kodeAkun",
      width: 110,
    },
    {
      title: "Jumlah",
      dataIndex: "realisasiKuantitas",
      width: 100,
      key: "jumlah",
    },
    {
      title: "Satuan",
      width: 90,
      dataIndex: "komponenBiayaSatuan",
      key: "satuan",
    },
    {
      title: "Total",
      key: "total",
      width: 130,
      dataIndex: "realisasiBelanja",
      render: (realisasiBelanja) => (
        <div className="text-right">{formatCurr(realisasiBelanja)}</div>
      ),
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Realisasi Pengadaan Barang`}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          scroll={{ x: "1300" }}
          columns={columns}
          dataSource={dataTable}
          bordered
        />
      </div>
    </>
  );
};

export default RealisasiPengadaan;
