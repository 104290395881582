/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../../components";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Select,
  Descriptions,
  Tag,
  Table,
  Space,
  Modal,
  Input,
  InputNumber,
  Button,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InputSearch from "../../../../../components/InputSearch";
import {
  ButtonExport,
  ButtonLog,
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableEdit,
} from "../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/id";
import {
  addFormData,
  autoSetAKBbyTipe,
  checkValidationSaldoRencana,
  createListSumberDana,
  defaultKoefisien,
  findSumberDana,
  formatRupiah,
  koefesienTarget,
  uuidv4,
} from "../../../../../utils/helper";
import ModalKomponen from "../Component/ModalKomponen";
import ModalSumberDana from "../Component/ModalSumberDana";
import ModalApproval from "../Component/ModalApproval";
import ModalAkb from "../Component/ModalAkb";
import FilterRincian from "../Component/FilterRincian";
import DescriptionRincian from "../Component/DescriptionRincian";
import {
  deleteRencanaRincianKegiatanIndikatif,
  editRencanaRincianKegiatanIndikatif,
  postRencanaRincianKegiatanIndikatif,
} from "../../../../../services/v2/planningservice/rencanakegiatanindikatif";
import { setStore } from "../../../../../redux/actions";
import EditableCell from "./EditTableCell";
import { getReferenceAll } from "../../../../../services/v2/referenceservice";
import {
  getBerkasRencana,
  getPlanningAll,
  postBerkasRencana,
} from "../../../../../services/v2/planningservice";
import { ExportToExcel } from "../../../../../components/Export/ExportToExcel";
import FormItem from "antd/es/form/FormItem";
import notifAlert from "../../../../../components/NotifAlert";
import axios from "axios";
import ModalLihatDokumenBos from "../../../../../components/Modal/ModalLihatDokumenBos";
import { planningService } from "../../../../../services/v2/constant";
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";

const RincianBelanjaIndikatif = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Rencana" },
    { path: "/", breadcrumbName: "Pagu Indikatif" },
    { path: "/", breadcrumbName: "Belanja" },
    { path: "/", breadcrumbName: "Rincian" },
  ];
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const dispatch = useDispatch();
  const [search, setSearch] = useState<any>(null);
  const [form] = Form.useForm();
  const [formTable] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths.data;
  const kodeRole = auth?.kode_role;
  const madrasahStatus = auths?.data?.madrasah?.status;
  const location = useLocation();
  const tmpRencana: any = location.state || null;
  const listMoment = moment.monthsShort();
  const [tipePencairan, setTipePencairan] = useState<any>([]);
  const tmpPajak = store.pajak || [];
  const tmpTipePencairan = tipePencairan.length
    ? tipePencairan.filter((item) => {
        return madrasahStatus && madrasahStatus === "n"
          ? item.isNegeri === "1"
          : item.kode === "4";
      })
    : [];
  const tmpSatuan = store.satuan || [];
  const [tmpJenisBelanja, setTmpJenisBelanja] = useState<any>([]);
  const [tmpRencanaBelanjaIndikatif, setTmpRencanaBelanjaIndikatif] =
    useState<any>([]);
  // const tmpRefKomponenBiaya = store.komponenBiaya || [];
  const tmpRincianBelanjaIndikatif =
    store.rencanaRincianKegiatanIndikatif || [];
  const tmpRekapSumberDana = store.rencanaRekapSumberDanaBelanja || [];
  /** data Tambah Rincian */
  const [tmpTambahKomponen, setTambahKomponen] = useState<any>(null);
  const [listKoefisien, setListKoefisien] = useState<any>(defaultKoefisien(1));
  const [alokasiPendapatan, setAlokasiPendapatan] = useState<any>([]);
  const [listAkb, setListAkb] = useState<any>(null);

  const [dataApproval, setDataApproval] = useState<any>(null);
  const [tambahTipePencairan, setTambahTipePencairan] = useState<any>(
    madrasahStatus && madrasahStatus === "n" ? null : "4",
  );

  const [tambahPajak, setTambahPajak] = useState<any>(null);
  const [id, setID] = useState<any>(uuidv4());
  const [hargaSatuan, setHargaSatuan] = useState<any>(0);
  const [totalTambahHarga, setTotalTambahHarga] = useState<any>(0);
  const [editSumberDana, setEditSumberDana] = useState<any>([]);
  /** dataEdit Rincian */
  const [disabledSaveEdit, setDisabledSaveEdit] = useState(true);
  const [disabledPembiayaan, setDisabledPembiayaan] = useState(false);
  const [titleModalKomponen, setTitleModalKomponen] = useState("");

  const [loading, setLoading] = useState(false);
  const [openModalKomponen, setOpenModalKomponen] = useState(false);
  const [openModalSumberDana, setOpenModalSumberDana] = useState(false);
  const [openModalAKB, setOpenModalAKB] = useState(false);
  const [openModalApproval, setOpenModalApproval] = useState(false);
  const [openModalDok, setOpenModalDok] = useState(false);
  const [fileDokumen, setFileDokumen] = useState<any>([]);

  const [standarPendidikan, setStandarPendidikan] = useState<any>(null);
  const [kegiatan, setKegiatan] = useState<any>(null);
  const [subKegiatan, setSubKegiatan] = useState<any>(null);
  const [editingKey, setEditingKey] = useState("");
  const [tmpRefKomponenBiaya, setTmpRefKomponenBiaya] = useState<any>([]);

  const getData = async () => {
    setLoading(true);
    const refKomponenBiaya = store?.komponenBiaya || [];
    if (refKomponenBiaya?.length) {
      setTmpRefKomponenBiaya(refKomponenBiaya);
    } else {
      const komponenBiaya =
        (await getReferenceAll("komponen-biaya", {
          tahun: auth.isTahun,
          kode_provinsi: auth.madrasah.kode_provinsi,
          kode_kabkota: auth.madrasah.kode_kabkota,
          status_madrasah: auth.madrasah?.status,
        })) || [];
      setTmpRefKomponenBiaya(komponenBiaya);
      setTimeout(() => {
        dispatch(setStore({ komponenBiaya }));
      }, 100);
    }
    const rencanaKegiatanIndikatifTmp = store?.rencanaKegiatanIndikatif || [];
    const rencanaKegiatanIndikatif = rencanaKegiatanIndikatifTmp.length
      ? rencanaKegiatanIndikatifTmp
      : await getPlanningAll("rencana/kegiatan-indikatif", {
          tahun: auths?.isTahun,
        });
    dispatch(setStore({ rencanaKegiatanIndikatif }));
    setTmpRencanaBelanjaIndikatif(rencanaKegiatanIndikatif);
    const resTipePencairan = await getReferenceAll("tipe-pencairan", {
      activated: 1,
      tahun: auths?.isTahun,
    });
    setTipePencairan(resTipePencairan || []);
    const resJenisBelanja = await getReferenceAll("jenis-belanja", {
      activated: 1,
    });
    setTmpJenisBelanja(resJenisBelanja || []);
    setLoading(false);
  };

  let tmpDataBelanja: any = null;

  standarPendidikan &&
    kegiatan &&
    subKegiatan &&
    tmpRencanaBelanjaIndikatif?.length &&
    tmpRencanaBelanjaIndikatif
      .filter(
        (item: any) =>
          item.kode_snp === standarPendidikan &&
          item.kode_kegiatan === kegiatan &&
          item.kode_sub_kegiatan === subKegiatan,
      )
      .map((item: any) => (tmpDataBelanja = { ...item }));

  const tmpDataRincian =
    (standarPendidikan &&
      kegiatan &&
      subKegiatan &&
      tmpRincianBelanjaIndikatif.filter(
        (item: any) =>
          item.rencanaKegiatan.kode_snp === standarPendidikan &&
          item.rencanaKegiatan.kode_kegiatan === kegiatan &&
          item.rencanaKegiatan.kode_sub_kegiatan === subKegiatan,
      )) ||
    [];

  let dataTable: any = search
    ? tmpDataRincian.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.komponenBiayaNama !== null &&
            (item.komponenBiayaNama || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nama_kegiatan !== null &&
            (item.nama_kegiatan || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpDataRincian;

  dataTable = dataTable.map((item: any) => {
    return { ...item, key: item.id };
  });

  /** Filter Data untuk filter select **/
  let filterDataSnp: any = [];
  let filterDataKegiatan: any = [];
  let filterDataSubKegiatan: any = [];
  tmpRencanaBelanjaIndikatif?.length &&
    // eslint-disable-next-line array-callback-return
    tmpRencanaBelanjaIndikatif.map((item: any) => {
      filterDataSnp.push(item.kode_snp);
      filterDataKegiatan.push(item.kode_kegiatan);
      filterDataSubKegiatan.push(item.kode_sub_kegiatan);
    });
  const tmpStandarPendidikan = store.snp?.length
    ? store.snp.filter((item: any) => filterDataSnp.includes(item.kode))
    : [];
  const tmpKegiatan = store.kegiatan?.length
    ? store.kegiatan.filter((item: any) =>
        filterDataKegiatan.includes(item.kode_kegiatan),
      )
    : [];
  const tmpSubKegiatan = store.subKegiatan?.length
    ? store.subKegiatan.filter((item: any) =>
        filterDataSubKegiatan.includes(item.kode),
      )
    : [];

  /** Filter Set Data  **/
  useEffect(() => {
    if (tmpRencana) {
      form.setFieldsValue({
        standarPendidikan: tmpRencana.kode_snp,
        kegiatan: tmpRencana.kode_kegiatan,
        subKegiatan: tmpRencana.kode_sub_kegiatan,
      });
      setStandarPendidikan(tmpRencana.kode_snp);
      setKegiatan(tmpRencana.kode_kegiatan);
      setSubKegiatan(tmpRencana.kode_sub_kegiatan);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpRencana]);

  useEffect(() => {
    countEditTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listKoefisien, tmpTambahKomponen, tambahPajak, totalTambahHarga]);

  /** Filter Set Data Jika select berubah  **/
  const handleChangeSelect = (values: any, field: string) => {
    switch (field) {
      case "standarPendidikan":
        setStandarPendidikan(values);
        form.setFieldsValue({ kegiatan: null, subKegiatan: null });
        setKegiatan(null);
        break;
      case "kegiatan":
        setKegiatan(values);
        setSubKegiatan(null);
        form.setFieldsValue({ subKegiatan: null });
        break;
      case "subKegiatan":
        setSubKegiatan(values);
        break;
    }
  };

  /** handle Open Modal Komponen Biaya */
  const handleOpenKomponen = (action: string) => {
    setOpenModalKomponen(true);
    setTitleModalKomponen(action);
  };

  /** Reponse Komponen Biaya */
  const handleSaveKomponen = (values: any, action: any) => {
    const tmpListKoef = defaultKoefisien(
      values?.listKomponen?.jenis_pembelanjaan_id,
    );

    setListKoefisien(tmpListKoef);
    setTambahKomponen(values);
    setTambahPajak(null);
    setDisabledPembiayaan(
      values?.listKomponen?.jenis_pembelanjaan_id > 1 ? true : false,
    );

    formTable.setFieldsValue({
      komponenBiayaNama: values.listKomponen?.nama,
      hargaSatuan: values.hargaSatuan,
      kodeJenisBelanja: null,
      sumberDana: null,
      ketBiayaLain: null,
      pajak: null,
      akb: null,
      tipePencairan: madrasahStatus === "n" ? null : "4",
      ...tmpListKoef,
    });

    if (values?.listKomponen?.jenis_pembelanjaan_id > 1) {
      handleSaveAKB(
        autoSetAKBbyTipe(
          values?.listKomponen?.jenis_pembelanjaan_id,
          tmpRencana,
        ),
        "Tambah",
      );
    } else {
      setListAkb(null);
      setAlokasiPendapatan([]);
      setEditSumberDana([]);
    }

    setOpenModalKomponen(false);
  };

  /** Handle  */
  const handleKoefisien = (values: any, action: string) => {
    let tmpListKoefesien: any = {};
    switch (action) {
      case "koef1Satuan":
        tmpListKoefesien = {
          ...listKoefisien,
          koef1Satuan: values,
        };

        break;
      case "koef2Satuan":
        tmpListKoefesien = {
          ...listKoefisien,
          koef2Satuan: values,
        };
        break;
      case "koef3Satuan":
        tmpListKoefesien = {
          ...listKoefisien,
          koef3Satuan: values,
        };
        break;
      case "koef4Satuan":
        tmpListKoefesien = {
          ...listKoefisien,
          koef4Satuan: values,
        };
        break;
      case "koef1Jumlah":
        tmpListKoefesien = {
          ...listKoefisien,
          koef1Jumlah: values,
        };
        break;
      case "koef2Jumlah":
        tmpListKoefesien = {
          ...listKoefisien,
          koef2Jumlah: values,
        };
        break;
      case "koef3Jumlah":
        tmpListKoefesien = {
          ...listKoefisien,
          koef3Jumlah: values,
        };
        break;
      case "koef4Jumlah":
        tmpListKoefesien = {
          ...listKoefisien,
          koef4Jumlah: values,
        };
        break;
    }
    setListKoefisien(tmpListKoefesien);
  };

  /** handle Open Modal Sumber Dana */
  const handleOpenSumberDana = (action: string) => {
    if (action === "Tambah") {
      if (tambahPajak) {
        setOpenModalSumberDana(true);
        setTitleModalKomponen(action);
      } else {
        Modal.error({
          title: "Kesalahan",
          content: "Anda belum memilih Ppn",
          okType: "danger",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      }
    } else {
      setOpenModalSumberDana(true);
      setTitleModalKomponen(action);
    }
  };

  /** Reponse Save Sumber Dana*/
  const handleSaveSumberDana = (values: any, action: any) => {
    let tmpSumberDana: string = "";
    let tmpEdit: any = [];

    setAlokasiPendapatan(values);
    // eslint-disable-next-line array-callback-return
    values.map((item: any, i: number) => {
      let namaSumberDana: any = null;
      tmpRekapSumberDana
        .filter((val: any) => val.id === item.rencanaPendapatanId)
        .map((val: any) => (namaSumberDana = val.nama_kode_sumber_dana));
      const jumlah = item.jumlah;
      tmpEdit.push({
        sumberDana: item.rencanaPendapatanId,
        kuantitas: item.quantity,
        jumlah: jumlah,
      });
      tmpSumberDana +=
        i > 0
          ? `, ${namaSumberDana} : ${formatRupiah(jumlah)}`
          : `${namaSumberDana} : ${formatRupiah(jumlah)}`;
    });

    setEditSumberDana(tmpEdit);

    formTable.setFieldsValue({ sumberDana: tmpSumberDana });
    changeValues();

    setOpenModalSumberDana(false);
  };

  /** handle Open Modal AKB */
  const handleOpenAKB = (action: string) => {
    if (tambahPajak) {
      setOpenModalAKB(true);
      setTitleModalKomponen(action);
    } else {
      Modal.error({
        title: "Kesalahan",
        content: "Anda belum memilih Ppn",
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }
  };

  /** Reponse Save AKB */
  const handleSaveAKB = (values: any, action: any) => {
    let tmpAkb: string = "";
    // eslint-disable-next-line array-callback-return
    listMoment.map((item: any, index: number) => {
      let i = index + 1;
      let jumlah =
        (values[`jumlahBulan${i}`] && values[`jumlahBulan${i}`]) || 0;
      tmpAkb += i > 1 ? `, ${item} : ${jumlah}` : `${item} : ${jumlah}`;
    });

    setListAkb(values);
    formTable.setFieldsValue({ akb: tmpAkb });
    changeValues();
    setOpenModalAKB(false);
  };

  const pajakData = tmpPajak.find((item) => item.kode === tambahPajak) || null;

  const totalTarget = koefesienTarget(listKoefisien);

  /** Harga Satuan Fn Tambah **/
  let listSatuan: any = [];
  listKoefisien.koef1Satuan && listSatuan.push(listKoefisien.koef1Satuan);
  listKoefisien.koef2Satuan && listSatuan.push(listKoefisien.koef2Satuan);
  listKoefisien.koef3Satuan && listSatuan.push(listKoefisien.koef3Satuan);

  const tmpValidModalSumberDana = {
    tagSumberDana: tmpDataBelanja?.tag_sumber_dana,
    totalSaldo: totalTambahHarga,
    hargaSatuan: hargaSatuan,
    totalKuantitas: totalTarget,
    jenisPajak: tambahPajak,
  };

  const tmpValidModalAkb = {
    totalKuantitas: totalTarget,
    startMonth: tmpDataBelanja?.bulan_pelaksanaan_start,
    endMonth: tmpDataBelanja?.bulan_pelaksanaan_end,
  };

  const isEditing = (record: any) => record.key === editingKey;

  const handleApproval = (record: any) => {
    setDataApproval(record);
    setOpenModalApproval(true);
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      const deleteRincianIndikatif =
        await deleteRencanaRincianKegiatanIndikatif(record.id);
      deleteRincianIndikatif &&
        Modal.success({
          title: "Sukses",
          content: "Hapus data rincian kegiatan berhasil",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      handleGetAllIndikatif();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleClear = () => {
    setEditingKey("");
    setID(uuidv4());
    setAlokasiPendapatan([]);
    setListAkb(null);
    setTambahKomponen(null);
    setTambahPajak(null);
    setTambahTipePencairan(madrasahStatus === "n" ? null : "4");
    setEditSumberDana([]);
    setListKoefisien(defaultKoefisien(1));
    setDisabledPembiayaan(false);
    setDisabledSaveEdit(true);
    formTable.resetFields();
  };

  const handleGetAllIndikatif = async () => {
    setLoading(true);
    try {
      const rencanaRincianKegiatanIndikatif = await getPlanningAll(
        "rencana-rincian-kegiatan-indikatif",
        { tahun: auth.isTahun },
      );
      const rencanaKegiatanIndikatif = await getPlanningAll(
        "rencana/kegiatan-indikatif",
        { tahun: auth.isTahun },
      );
      const rencanaRekapSumberDanaBelanja = await getPlanningAll(
        "rencana-rekap-sumber-dana-belanja",
        { tahun: auth.isTahun },
      );
      const dataTmp = {
        rencanaKegiatanIndikatif,
        rencanaRekapSumberDanaBelanja,
        rencanaRincianKegiatanIndikatif,
      };
      setTimeout(() => {
        dispatch(setStore(dataTmp));
      }, 100);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const rolesCheck =
    kodeRole === "bendahara_madrasah" || kodeRole === "staf_madrasah";

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "Komponen",
      dataIndex: "komponenBiayaNama",
      name: "komponenBiayaNama",
      key: "komponenBiayaNama",
      inputType: "text",
      width: "300px",
      editable: true,
    },
    {
      title: "Keterangan Komponen Biaya",
      key: "ketBiayaLain",
      name: "ketBiayaLain",
      dataIndex: "ketBiayaLain",
      rules: [
        {
          required:
            tmpTambahKomponen && tmpTambahKomponen?.listKomponen?.belanja_lain,
          message: "Keterangan Komponen Biaya tidak boleh kosong!",
        },
      ],
      editable: true,
      width: 250,
    },
    {
      title: "Kode Akun",
      key: "kodeAkun",
      name: "kodeJenisBelanja",
      width: "300px",
      editable: true,
      render: (record) =>
        record.kodeJenisBelanja + "-" + record.namaJenisBelanja,
    },
    {
      title: "Koefisien 1",
      key: "koefisien1",
      name: "koefisien1",
      editable: true,
      children: [
        {
          title: "Target",
          width: 110,
          name: "koef1Jumlah",
          key: "koef1Jumlah",
          dataIndex: "koef1Jumlah",
          editable: true,
          render: (koef1Jumlah) => koef1Jumlah || "-",
        },
        {
          title: "Satuan",
          width: 110,
          name: "koef1Satuan",
          key: "koef1Satuan",
          dataIndex: "koef1Satuan",
          editable: true,
          render: (koef1Satuan) => koef1Satuan || "-",
        },
      ],
    },
    {
      title: "Koefisien 2",
      key: "koefisien2",
      name: "koefisien2",
      editable: true,
      children: [
        {
          title: "Target",
          width: 110,
          name: "koef2Jumlah",
          key: "koef2Jumlah",
          editable: true,
          dataIndex: "koef2Jumlah",
          render: (koef2Jumlah) => koef2Jumlah || "-",
        },
        {
          title: "Satuan",
          width: 110,
          key: "koef2Satuan",
          name: "koef2Satuan",
          editable: true,
          dataIndex: "koef2Satuan",
          render: (koef2Satuan) => koef2Satuan || "-",
        },
      ],
    },
    {
      title: "Koefisien 3",
      key: "koefisien3",
      name: "koefisien3",
      editable: true,
      children: [
        {
          title: "Target",
          width: 110,
          key: "koef3Jumlah",
          name: "koef3Jumlah",
          editable: true,
          dataIndex: "koef3Jumlah",
          render: (koef3Jumlah) => koef3Jumlah || "-",
        },
        {
          title: "Satuan",
          width: 110,
          name: "koef3Satuan",
          key: "koef3Satuan",
          inputType: "select",
          listInput: [{ kode: "test", name: "test", value: "test" }],
          editable: true,
          dataIndex: "koef3Satuan",
          render: (koef3Satuan) => koef3Satuan || "-",
        },
      ],
    },
    {
      title: "Koefisien 4",
      key: "koef4Jumlah",
      name: "koefisien4",
      editable: true,
      children: [
        {
          title: "Target",
          width: 110,
          editable: true,
          name: "koef4Jumlah",
          key: "koef4Jumlah",
          dataIndex: "koef4Jumlah",
          render: (koef4Jumlah) => koef4Jumlah || "-",
        },
        {
          title: "Satuan",
          width: 110,
          editable: true,
          key: "koef4Satuan",
          name: "koef4Satuan",
          dataIndex: "koef4Satuan",
          render: (koef4Satuan) => koef4Satuan || "-",
        },
      ],
    },
    {
      title: "Harga Satuan (Rupiah)",
      key: "hargaSatuan",
      name: "hargaSatuan",
      width: 210,
      editable: true,
      dataIndex: "hargaSatuan",
      render: (hargaSatuan) => formatRupiah(hargaSatuan),
    },
    {
      title: "PPN",
      key: "pajak",
      name: "pajak",
      width: "200px",
      editable: true,
      dataIndex: "pajak",
      render: (pajak) =>
        tmpPajak
          .filter((item) => item.kode === pajak)
          .map((item) => item.deskripsi),
    },
    {
      title: "Total Harga (Rupiah)",
      key: "totalHarga",
      width: 220,
      name: "totalHarga",
      dataIndex: "jumlahTotal",
      editable: true,
      render: (jumlahTotal) => formatRupiah(jumlahTotal),
    },
    {
      title: "Sumber Dana",
      key: "sumberdana",
      width: 250,
      name: "sumberDana",
      editable: true,
      rules: [
        {
          validator: () =>
            checkValidationSaldoRencana(
              tmpTambahKomponen,
              tmpRekapSumberDana,
              dataTable,
              tmpValidModalSumberDana,
              editingKey,
            ),
        },
      ],
      render: (record) =>
        record.alokasiPendapatan.length &&
        record.alokasiPendapatan.map((item: any) => (
          <div key={`div${record.id}`} className="flex justify-between">
            <div>{item.rencana_pendapatan.nama_sumber_dana}</div>
            <div>{formatRupiah(item.jumlah)}</div>
          </div>
        )),
    },
    {
      title: "Tipe Pencairan",
      key: "tipePencairanNama",
      width: 200,
      rules: [
        {
          required: true,
          message: "Tipe Pencairan tidak boleh kosong!",
        },
      ],
      editable: true,
      name: "tipePencairan",
      dataIndex: "tipePencairanNama",
    },
    {
      title: "AKB",
      key: "akb",
      name: "akb",
      width: 255,
      editable: true,
      render: (record) => {
        return (
          <Descriptions layout="horizontal" size="small">
            {listMoment.map((item: any, i: number) => (
              <Descriptions.Item key={`des${record.id}-${i}`} label={item}>
                {record[`jumlahBulan${i + 1}`]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        );
      },
    },
    {
      title: "Dokumen RAB",
      key: "dokumenRAB",
      name: "dokumenRAB",
      width: 160,
      render: (record) => {
        let checkKomponen: any =
          tmpRefKomponenBiaya.find(
            (item: any) =>
              item.komponen_biaya_harga_id === record?.komponenBiayaHargaId,
          ) || null;
        return (
          <>
            {checkKomponen?.jenis_pembelanjaan_id === 5 && (
              <Button onClick={() => handleOpenDokumen(record?.id)}>
                Lihat Dokumen
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "Komentar",
      key: "komentar",
      name: "komentar",
      dataIndex: "komentar",
      width: 250,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 120,
      fixed: "right",
      align: "center",
      render: (status, record) => {
        let color: any =
          (status === "MENUNGGU" && "#ffca27") ||
          (status === "DITOLAK" && "#f44436") ||
          (status === "DISETUJUI" && "#008000");
        const editable = isEditing(record);
        return editable ? (
          <Button
            onClick={() => {
              handleClear();
            }}>
            Batal
          </Button>
        ) : (
          <Tag color={color}>{status}</Tag>
        );
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      align: "center",
      width: 120,
      fixed: "right",
      render: (record) => {
        const editable = isEditing(record);

        const tooltipsBtn =
          rolesCheck && record.status === "DISETUJUI"
            ? "Data harus Ditolak Dahulu oleh Kamad"
            : "";
        const disableBtn = rolesCheck && record.status === "DISETUJUI";
        return kodeRole === "kepala_madrasah" ? (
          <Space>
            <ButtonTableApproval
              tooltips="Approval"
              onClick={() => handleApproval(record)}
            />
          </Space>
        ) : editable ? (
          <Button
            type="primary"
            loading={loading}
            disabled={disabledSaveEdit}
            onClick={() => formTable.submit()}>
            Simpan
          </Button>
        ) : (
          <Space>
            <ButtonTableEdit
              onClick={() => handleEdit(record)}
              disabled={disableBtn}
              tooltips={tooltipsBtn}
            />
            <ButtonTableDelete
              disabled={disableBtn}
              tooltips={tooltipsBtn}
              loading={loading}
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  onOk() {
                    return handleDelete(record);
                  },
                  onCancel() {},
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                  centered: true,
                  wrapClassName: "erkam-custom-modal",
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.name,
        name: col.name,
        title: col.title,
        rules: col?.rules || null,
        tmpSatuan,
        tmpPajak,
        tmpTipePencairan,
        tmpTambahKomponen,
        tmpJenisBelanja,
        listKoefisien,
        listSatuan,
        hargaSatuan,
        totalTambahHarga,
        madrasahStatus,
        disabledPembiayaan,
        handleOpenKomponen: handleOpenKomponen,
        handleOpenSumberDana: handleOpenSumberDana,
        handleOpenAKB: handleOpenAKB,
        editing: isEditing(record),
      }),
    };
    if (col.children) {
      newCol.children = col.children.map((item: any) => mapColumns(item));
    }
    return newCol;
  };

  const mergedColumns = columns.map((item: any) => mapColumns(item));

  const handleEdit = (record: any & { key: React.Key }) => {
    let tmpAkb: string = "";
    let listAkb: any = {};
    listMoment.length &&
      // eslint-disable-next-line array-callback-return
      listMoment.map((item: any, index: number) => {
        let i = index + 1;
        let jumlah =
          (record[`jumlahBulan${i}`] && record[`jumlahBulan${i}`]) || 0;
        tmpAkb += i > 1 ? `, ${item} : ${jumlah}` : ` ${item} : ${jumlah}`;
        listAkb[`jumlahBulan${i}`] = record[`jumlahBulan${i}`] || 0;
      });

    setListAkb(listAkb);
    setTambahPajak(record.pajak);
    let tmpSumDana: string = createListSumberDana(record.alokasiPendapatan);
    let edSumberDana = record.alokasiPendapatan.map((item: any, i: number) => {
      return {
        sumberDana: item.rencana_pendapatan.id,
        kuantitas: item.quantity,
        jumlah: item.jumlah,
      };
    });
    setEditSumberDana(edSumberDana);
    const tmpAlokasiEdit: any = record.alokasiPendapatan.map((item: any) => {
      return {
        rencanaPendapatanId: item.rencana_pendapatan.id,
        jumlah: item.jumlah,
        quantity: item.quantity,
        kodePajak: item.kode_pajak,
        jenisPajak: item.jenis_pajak,
      };
    });
    setAlokasiPendapatan(tmpAlokasiEdit);
    setListKoefisien({
      koef1Jumlah: record.koef1Jumlah,
      koef2Jumlah: record.koef2Jumlah,
      koef3Jumlah: record.koef3Jumlah,
      koef4Jumlah: record.koef4Jumlah,
      koef1Satuan: record.koef1Satuan,
      koef2Satuan: record.koef2Satuan,
      koef3Satuan: record.koef3Satuan,
      koef4Satuan: record.koef4Satuan,
    });
    setTambahTipePencairan(record.tipePencairanKode);

    let tmpListKomponen: any =
      tmpRefKomponenBiaya.find(
        (item: any) =>
          item.komponen_biaya_harga_id === record.komponenBiayaHargaId,
      ) || null;

    setTambahKomponen({
      kodeHarga: record.kodeHarga,
      hargaSatuan: record.hargaSatuan,
      listKomponen: tmpListKomponen,
    });

    setDisabledPembiayaan(
      tmpListKomponen?.jenis_pembelanjaan_id > 1 ? true : false,
    );

    formTable.setFieldsValue({
      ...record,
      tipePencairan: record.tipePencairanKode,
      akb: tmpAkb,
      sumberDana: tmpSumDana,
      totalHarga: record.totalKuantitas * record.hargaSatuan,
    });

    setEditingKey(record.key);
    setID(record.id);
  };

  const handleExport = () => {
    try {
      let xls = tmpDataRincian.map((item: any) => {
        let tmpAkb: string = "";
        listMoment.length &&
          // eslint-disable-next-line array-callback-return
          listMoment.map((items: any, index: number) => {
            let i = index + 1;
            let jumlah =
              (item[`jumlahBulan${i}`] && item[`jumlahBulan${i}`]) || 0;
            tmpAkb +=
              i > 1 ? `, ${items} : ${jumlah}` : ` ${items} : ${jumlah}`;
          });
        let tmpSumberDana: string = "";
        // eslint-disable-next-line array-callback-return
        item.alokasiPendapatan.map((items: any, i: number) => {
          tmpSumberDana +=
            i > 0
              ? `, ${items.rencana_pendapatan.nama_sumber_dana} : ${items.jumlah}`
              : `${items.rencana_pendapatan.nama_sumber_dana} : ${items.jumlah}`;
        });
        return {
          KOMPONEN: item.komponenBiayaNama,
          KETERANGAN_KOMPONEN_BIAYA: item.ketBiayaLain,
          KODE_AKUN: item.kodeJenisBelanja + " " + item.namaJenisBelanja,
          KOEFISIEN_1_JUMLAH: item.koef1Jumlah,
          KOEFISIEN_1_SATUAN: item.koef1Satuan,
          KOEFISIEN_2_JUMLAH: item.koef2Jumlah,
          KOEFISIEN_2_SATUAN: item.koef2Satuan,
          KOEFISIEN_3_JUMLAH: item.koef3Jumlah,
          KOEFISIEN_3_SATUAN: item.koef3Satuan,
          KOEFISIEN_4_JUMLAH: item.koef4Jumlah,
          KOEFISIEN_4_SATUAN: item.koef4Satuan,
          HARGA_SATUAN: item.hargaSatuan,
          PAJAK: item.pajak,
          TOTAL_HARGA: item.jumlahTotal,
          SUMBER_DANA: tmpSumberDana,
          TIPE_PENCAIRAN: item.tipePencairanNama,
          AKB: tmpAkb,
          KOMENTAR: item.komentar,
          STATUS: item.status,
        };
      });
      ExportToExcel(xls, "daftar-rincian-indikatif");
      Modal.success({
        title: "Success",
        content: "Data berhasil di export",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    } catch (error) {
      Modal.error({
        title: "Kesalahan",
        content: "Data Gagal di export",
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }
  };

  const handleFormEditChange = (val, values: any) => {
    for (let obj in val) {
      switch (obj) {
        case "pajak":
          setTambahPajak(values[obj]);
          formTable.setFieldsValue({ sumberDana: null });
          break;

        case "tipePencairan":
          setTambahTipePencairan(values[obj]);
          break;
        case "koef1Jumlah":
        case "koef2Jumlah":
        case "koef3Jumlah":
        case "koef4Jumlah":
        case "koef1Satuan":
        case "koef2Satuan":
        case "koef3Satuan":
        case "koef4Satuan":
          handleKoefisien(values[obj], obj);
          break;
      }
    }
    changeValues();
  };

  const changeValues = () => {
    const values: any = formTable.getFieldsValue();

    let tmpButtonSave =
      tmpTambahKomponen &&
      values.kodeJenisBelanja &&
      values.koef1Jumlah &&
      values.koef1Satuan &&
      values.sumberDana &&
      values.akb
        ? false
        : true;

    setDisabledSaveEdit(tmpButtonSave);
  };

  const countEditTotal = () => {
    let pajakEdit = tmpPajak.find((item) => item.kode === tambahPajak);
    let totalKuantitasSumber = 0;
    alokasiPendapatan.length &&
      alokasiPendapatan.map((item) => (totalKuantitasSumber += item.quantity));

    const editTotalTarget = koefesienTarget(listKoefisien);

    if (
      totalKuantitasSumber !== editTotalTarget &&
      tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id === 1
    ) {
      formTable.setFieldsValue({
        sumberDana: null,
        akb: null,
      });
      setAlokasiPendapatan([]);
      setListAkb(null);
      changeValues();
    }

    /** Set harga untuk semesteran dan tahunan **/
    if (
      tambahPajak &&
      tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id > 1
    ) {
      let alokasiSumberDana: any = findSumberDana(
        tmpRekapSumberDana,
        tmpTambahKomponen,
        tmpValidModalSumberDana?.tagSumberDana,
      );

      if (alokasiSumberDana) {
        setAlokasiPendapatan([
          {
            jenisPajak: pajakEdit?.nama,
            jumlah: tmpValidModalSumberDana?.totalSaldo,
            kodePajak: pajakEdit?.kode,
            quantity: tmpValidModalSumberDana?.totalKuantitas,
            rencanaPendapatanId: alokasiSumberDana?.id,
          },
        ]);

        setEditSumberDana([
          {
            kuantitas: tmpValidModalSumberDana?.totalKuantitas,
            jumlah: tmpValidModalSumberDana?.totalSaldo,
            rencanaPendapatanId: alokasiSumberDana?.id,
          },
        ]);

        formTable.setFieldsValue({
          sumberDana: `${alokasiSumberDana?.nama_kode_sumber_dana} - ${tmpValidModalSumberDana?.totalSaldo}`,
        });
        changeValues();
      }
    }
    /** Harga Satuan Fn Tambah **/

    const tmpHargaSatuan =
      (tmpTambahKomponen && tmpTambahKomponen.hargaSatuan) || 0;
    const hargaSatuanPajak =
      (tambahPajak === "ppn" &&
        Math.round(tmpHargaSatuan * ((pajakEdit.koefisien + 100) / 100))) ||
      tmpHargaSatuan;
    /** Total Harga Fn Tambah **/
    const editTotalTambahHarga = hargaSatuanPajak * editTotalTarget;

    setTotalTambahHarga(
      (tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id > 1 &&
        hargaSatuanPajak) ||
        editTotalTambahHarga,
    );

    setHargaSatuan(hargaSatuanPajak);

    formTable.setFieldsValue({
      totalHarga:
        (tmpTambahKomponen?.listKomponen?.jenis_pembelanjaan_id > 1 &&
          hargaSatuanPajak) ||
        editTotalTambahHarga,
    });
  };

  const handleSaveForm = async (values: any) => {
    setLoading(true);
    let filterTipePencairan: any = tmpTipePencairan.find(
      (item: any) => item.kode === values?.tipePencairan,
    );
    const jenisBelanjaData =
      tmpJenisBelanja.find((item) => item.kode === values?.kodeJenisBelanja) ||
      null;

    const payload = [
      {
        ...listKoefisien,
        ...listAkb,
        id: id,
        koefPersenPajak: pajakData && pajakData.koefisien,
        kodeHarga: tmpTambahKomponen?.kodeHarga,
        rencanaKegiatanId: tmpDataBelanja?.id,
        kodeJenisBelanja: values?.kodeJenisBelanja,
        namaJenisBelanja: jenisBelanjaData && jenisBelanjaData.nama,
        komponenBiayaHargaId:
          tmpTambahKomponen?.listKomponen?.komponen_biaya_harga_id,
        komponenBiayaNama: tmpTambahKomponen?.listKomponen?.nama,
        hargaSatuan: values?.hargaSatuan,
        totalKuantitas: totalTarget,
        jumlahTotal: values?.totalHarga,
        pajak: pajakData && pajakData.kode,
        tipePencairanId: filterTipePencairan.id,
        tipePencairanKode: filterTipePencairan.kode,
        tipePencairanNama: filterTipePencairan.nama,
        alokasiPendapatan: alokasiPendapatan,
        ketBiayaLain: values?.ketBiayaLain,
      },
    ];

    if (!editingKey) {
      try {
        await postRencanaRincianKegiatanIndikatif(payload);

        Modal.success({
          title: "Sukses",
          content: "Data Berhasil di simpan",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
        handleGetAllIndikatif();
        handleClear();
      } catch (error) {
        console.log(error);
      }
    } else {
      const payloadEdit: any = {
        ...listKoefisien,
        ...listAkb,
        id: id,
        koefPersenPajak: pajakData && pajakData.koefisien,
        kodeHarga: tmpTambahKomponen?.kodeHarga,
        rencanaKegiatanId: tmpDataBelanja?.id,
        kodeJenisBelanja: values?.kodeJenisBelanja,
        namaJenisBelanja: jenisBelanjaData && jenisBelanjaData.nama,
        komponenBiayaHargaId:
          tmpTambahKomponen?.listKomponen?.komponen_biaya_harga_id,
        komponenBiayaNama: tmpTambahKomponen?.listKomponen?.nama,
        hargaSatuan: values?.hargaSatuan,
        totalKuantitas: totalTarget,
        jumlahTotal: values?.totalHarga,
        pajak: pajakData && pajakData.kode,
        tipePencairanId: filterTipePencairan.id,
        tipePencairanKode: filterTipePencairan.kode,
        tipePencairanNama: filterTipePencairan.nama,
        alokasiPendapatan: alokasiPendapatan,
        ketBiayaLain: values?.ketBiayaLain,
      };

      try {
        await editRencanaRincianKegiatanIndikatif(payloadEdit);

        Modal.success({
          title: "Sukses",
          content: "Data Berhasi di simpan",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });

        handleGetAllIndikatif();
        handleClear();
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const handleSaveUpload = async (fileUpload: any) => {
    if (fileUpload.length) {
      let fileUploadTmp: any = [];
      fileUpload.map(async (item: any) => {
        fileUploadTmp.push(item.originFileObj);
      });
      let payload: any = {
        idRincianRencanaKegiatan: id,
        tahun: auths.isTahun,
        nsm: auth.madrasah?.nsm,
        file: fileUploadTmp,
      };
      let params: any = addFormData(payload);

      try {
        return await postBerkasRencana(params);
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  const handleOpenDokumen = async (id: any) => {
    let dokumenRab: any = [];
    try {
      let res = await getBerkasRencana(id, auth.madrasah?.nsm, auths.isTahun);
      if (res) {
        dokumenRab.push(res);
        getDownloadDokumen(res?.fileName);
        // getDownloadDokumen(res.)
      }
    } catch (error) {
      notifAlert({
        type: "error",
        description: "File RAB Tidak ditemukan!!",
      });
      console.log(error);
    }
  };

  const getDownloadDokumen = async (params: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-rencana-definitif/download?fileName=${params}`,
        {
          withCredentials: false,
          responseType: "arraybuffer",

          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + auth?.token,
            "X-Erkam-Year": auth?.isTahun,
          },
        },
      )
      .then(function (response: any) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        setFileDokumen(link);
        setOpenModalDok(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const closeDokument = () => {
    setOpenModalDok(false);
    setFileDokumen(null);
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Rincian Rencana Belanja Indikatif"
        back={true}
        toBack={() =>
          route.push({
            pathname: "../belanja",
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
            },
          })
        }
      />

      <FilterRincian
        form={form}
        tmpStandarPendidikan={tmpStandarPendidikan}
        standarPendidikan={standarPendidikan}
        tmpKegiatan={tmpKegiatan}
        kegiatan={kegiatan}
        tmpSubKegiatan={tmpSubKegiatan}
        handleChangeSelect={handleChangeSelect}
      />

      <DescriptionRincian
        title="Pagu Indikatif"
        tmpDataBelanja={tmpDataBelanja}
      />

      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              <ButtonExport
                disabled={tmpDataRincian.length ? false : true}
                onClick={handleExport}
              />
              <ButtonLog
                disabled={!tmpDataBelanja}
                onClick={() =>
                  route.push({
                    pathname: "rincian/logs",
                    state: tmpDataBelanja?.id,
                  })
                }
              />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Form
            form={formTable}
            component={false}
            onFinish={handleSaveForm}
            onValuesChange={handleFormEditChange}>
            <Table
              columns={mergedColumns}
              dataSource={dataTable}
              loading={loading}
              className="tableRencana"
              rowClassName="editable-row"
              scroll={{ x: 1500 }}
              sticky
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={false}
              bordered
              summary={() =>
                tmpDataBelanja &&
                kodeRole !== "kepala_madrasah" &&
                !editingKey && (
                  <Table.Summary fixed={"bottom"}>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} key="tambahKomponen">
                        <FormItem name="komponenBiayaNama">
                          <Input
                            key="itambahKomponen"
                            placeholder="Komponen Biaya"
                            disabled
                            suffix={
                              <Button
                                size="small"
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => handleOpenKomponen("Tambah")}
                              />
                            }
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} key="tambahKeterangan">
                        <FormItem
                          name="ketBiayaLain"
                          rules={[
                            {
                              required:
                                tmpTambahKomponen &&
                                tmpTambahKomponen?.listKomponen?.belanja_lain,
                              message:
                                "Keterangan Komponen Biaya tidak boleh kosong!",
                            },
                          ]}>
                          <Input
                            disabled={
                              tmpTambahKomponen &&
                              tmpTambahKomponen?.listKomponen?.belanja_lain
                                ? false
                                : true
                            }
                            name="keteranganKomponen"
                            placeholder="Keterangan Komponen Biaya"
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} key="tambahAkun">
                        <FormItem name="kodeJenisBelanja">
                          <Select
                            key="tambahAkun"
                            placeholder="Kode Akun"
                            style={{ width: "100%" }}>
                            {tmpTambahKomponen &&
                              tmpJenisBelanja.length &&
                              tmpJenisBelanja
                                .filter((item: any) =>
                                  tmpTambahKomponen.listKomponen?.jenis_belanja.includes(
                                    item.kode,
                                  ),
                                )
                                .map((item: any) => (
                                  <Select.Option value={item.kode}>
                                    <Tooltip
                                      title={`${item.kode}-${item.nama}`}>
                                      {item.kode}-{item.nama}
                                    </Tooltip>
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={3} key="tambahKoe1target">
                        <FormItem name="koef1Jumlah">
                          <InputNumber
                            min={1}
                            type="number"
                            key="itambahKoe1Target"
                            disabled={
                              tmpTambahKomponen ? disabledPembiayaan : true
                            }
                            placeholder="0"
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} key="tambahKoe1satuan">
                        <FormItem name="koef1Satuan">
                          <Select
                            key="itambahKoe1Satuan"
                            showSearch
                            placeholder="Satuan"
                            disabled={
                              tmpTambahKomponen ? disabledPembiayaan : true
                            }
                            style={{ width: "100%" }}>
                            {tmpSatuan.length &&
                              tmpSatuan
                                .sort((a, b) => a.nama.localeCompare(b.nama))
                                .map((item: any, i: number) => (
                                  <Select.Option
                                    key={`tamk1${i}`}
                                    value={item.kode}>
                                    {item.nama}
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} key="tambahKoe2target">
                        <FormItem name="koef2Jumlah">
                          <InputNumber
                            min={1}
                            type="number"
                            key="itambahKoe2Target"
                            disabled={
                              listKoefisien.koef1Jumlah > 0 &&
                              listKoefisien.koef1Satuan
                                ? disabledPembiayaan
                                : true
                            }
                            placeholder="0"
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} key="tambahKoe2satuan">
                        <FormItem name="koef2Satuan">
                          <Select
                            key="itambahKoe2Satuan"
                            showSearch
                            allowClear
                            placeholder="Satuan"
                            disabled={
                              listKoefisien.koef1Satuan
                                ? disabledPembiayaan
                                : true
                            }
                            style={{ width: "100%" }}>
                            {tmpSatuan.length &&
                              tmpSatuan
                                .filter(
                                  (item: any) =>
                                    !listSatuan.includes(item.kode),
                                )
                                .sort((a, b) => a.nama.localeCompare(b.nama))
                                .map((item: any, i: number) => (
                                  <Select.Option
                                    key={`tamk2${i}`}
                                    value={item.kode}>
                                    {item.nama}
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} key="tambahKoe3target">
                        <FormItem name="koef3Jumlah">
                          <InputNumber
                            min={1}
                            type="number"
                            disabled={
                              listKoefisien.koef2Jumlah > 0 &&
                              listKoefisien.koef2Satuan
                                ? false
                                : true
                            }
                            key="itambahKoe3Target"
                            placeholder="0"
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} key="tambahKoe3satuan">
                        <FormItem name="koef3Satuan">
                          <Select
                            key="itambahKoe3Satuan"
                            placeholder="Satuan"
                            showSearch
                            allowClear
                            disabled={listKoefisien.koef2Satuan ? false : true}
                            style={{ width: "100%" }}>
                            {tmpSatuan.length &&
                              tmpSatuan
                                .filter(
                                  (item: any) =>
                                    !listSatuan.includes(item.kode),
                                )
                                .sort((a, b) => a.nama.localeCompare(b.nama))
                                .map((item: any, i: number) => (
                                  <Select.Option
                                    key={`tamk3${i}`}
                                    value={item.kode}>
                                    {item.nama}
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} key="tambahKoe4target">
                        <FormItem name="koef4Jumlah">
                          <InputNumber
                            min={1}
                            type="number"
                            disabled={
                              listKoefisien.koef3Jumlah > 0 &&
                              listKoefisien.koef3Satuan
                                ? false
                                : true
                            }
                            key="itambahKoe4Target"
                            placeholder="0"
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} key="tambahKoe4satuan">
                        <FormItem name="koef4Satuan">
                          <Select
                            key="itambahKoe4Satuan"
                            showSearch
                            allowClear
                            placeholder="Satuan"
                            disabled={listKoefisien.koef3Satuan ? false : true}
                            style={{ width: "100%" }}>
                            {tmpSatuan.length &&
                              tmpSatuan
                                .filter(
                                  (item: any) =>
                                    !listSatuan.includes(item.kode),
                                )
                                .sort((a, b) => a.nama.localeCompare(b.nama))
                                .map((item: any, i: number) => (
                                  <Select.Option
                                    key={`tamk4${i}`}
                                    value={item.kode}>
                                    {item.nama}
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} key="tambahHargaSatuan">
                        <FormItem name="hargaSatuan">
                          <InputNumber
                            key="inputtambahHargaSatuan"
                            style={{
                              width: "100%",
                              background: "#fff",
                              color: "#000",
                            }}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            //  prefix="Rp."
                            disabled={true}
                            placeholder={"Harga Satuan"}
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12} key="tambahPajak">
                        <FormItem name="pajak" noStyle={false}>
                          <Select
                            key="itambahpajak"
                            value={tambahPajak}
                            placeholder={
                              tmpTambahKomponen
                                ? "PPN"
                                : "Komponen masih kosong"
                            }
                            style={{ width: "100%" }}>
                            {tmpTambahKomponen &&
                              tmpPajak.length &&
                              tmpPajak
                                .filter(
                                  (item: any) =>
                                    ![
                                      "pph21",
                                      "pph22",
                                      "ppnTerhutang",
                                      "pph23",
                                    ].includes(item.kode),
                                )
                                .map((item: any, i: number) => (
                                  <Select.Option
                                    key={`ipaj${i}`}
                                    value={item.kode}>
                                    {item.deskripsi}
                                  </Select.Option>
                                ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13} key="tambahTotalHarga">
                        <FormItem name="totalHarga" noStyle={false}>
                          <InputNumber
                            key="inputtambahTotalHarga"
                            style={{
                              width: "100%",
                              background: "#fff",
                              color: "#000",
                            }}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            // prefix="Rp."
                            disabled
                            placeholder={"Total Harga"}
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14} key="tambahSumberDana">
                        <FormItem
                          name="sumberDana"
                          rules={[
                            {
                              validator: () =>
                                checkValidationSaldoRencana(
                                  tmpTambahKomponen,
                                  tmpRekapSumberDana,
                                  dataTable,
                                  tmpValidModalSumberDana,
                                  editingKey,
                                ),
                            },
                          ]}
                          hasFeedback>
                          <Input
                            name="tambahSumberDana"
                            key="itambahSumberDana"
                            placeholder={
                              tambahPajak ? "Sumber Dana" : "Pajak masih kosong"
                            }
                            disabled
                            suffix={
                              <Button
                                size="small"
                                type="primary"
                                disabled={disabledPembiayaan}
                                icon={<PlusOutlined />}
                                onClick={() => handleOpenSumberDana("Tambah")}
                              />
                            }
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={15} key="tambahTipePencairan">
                        <FormItem
                          name="tipePencairan"
                          initialValue={tambahTipePencairan}
                          rules={[
                            {
                              required: true,
                              message: "Tipe Pencairan tidak boleh kosong!",
                            },
                          ]}>
                          <Select
                            key="itambahTipePencairan"
                            disabled={
                              madrasahStatus && madrasahStatus === "n"
                                ? false
                                : true
                            }
                            placeholder="Tipe Pencairan"
                            style={{ width: "100%" }}>
                            {tmpTipePencairan.length &&
                              tmpTipePencairan.map((item: any, i: number) => (
                                <Select.Option
                                  key={`itp${i}`}
                                  value={item.kode}>
                                  {item.nama}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16} key="tambahAkb">
                        <FormItem name="akb">
                          <Input
                            name="tambahAkb"
                            key="itambahAkb"
                            placeholder="AKB"
                            disabled
                            suffix={
                              <Button
                                size="small"
                                type="primary"
                                disabled={disabledPembiayaan}
                                icon={<PlusOutlined />}
                                onClick={() => handleOpenAKB("Tambah")}
                              />
                            }
                          />
                        </FormItem>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17} key="berkasRAB">
                        {tmpTambahKomponen?.listKomponen
                          ?.jenis_pembelanjaan_id === 5 && (
                          <Button onClick={() => handleOpenDokumen(id)}>
                            Lihat Dokumen
                          </Button>
                        )}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={18}
                        key="tambahKomentar"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={19}
                        colSpan={2}
                        key="aksiTambah">
                        <FormItem>
                          <Space>
                            <Button key="btnTambahCancel" onClick={handleClear}>
                              Batal
                            </Button>
                            <Button
                              key="btnTambahSave"
                              type="primary"
                              disabled={disabledSaveEdit}
                              loading={loading}
                              onClick={() => formTable.submit()}>
                              Simpan
                            </Button>
                          </Space>
                        </FormItem>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }
            />
          </Form>
        </div>
      </div>

      <ModalKomponen
        openModal={openModalKomponen}
        title={titleModalKomponen}
        tipe={dataState}
        onClose={() => setOpenModalKomponen(!openModalKomponen)}
        saveKomponen={handleSaveKomponen}
        saveUpload={handleSaveUpload}
      />

      <ModalSumberDana
        openModal={openModalSumberDana}
        validData={tmpValidModalSumberDana}
        editSumberDana={editSumberDana}
        title={titleModalKomponen}
        tag="indikatif"
        onClose={() => setOpenModalSumberDana(!openModalSumberDana)}
        saveSumberDana={handleSaveSumberDana}
      />

      <ModalAkb
        openModal={openModalAKB}
        validData={tmpValidModalAkb}
        title={titleModalKomponen}
        onClose={() => setOpenModalAKB(!openModalAKB)}
        saveAKB={handleSaveAKB}
        editAkb={listAkb}
      />

      <ModalApproval
        openModal={openModalApproval}
        title="Approval Rencana Rincian Kegiatan Indikatif"
        data={dataApproval}
        onReload={handleGetAllIndikatif}
        onClose={() => setOpenModalApproval(!openModalApproval)}
      />

      <ModalLihatDokumenBos
        openModalPriview={openModalDok}
        pdfFile={fileDokumen}
        onClose={closeDokument}
      />
    </>
  );
};

export default RincianBelanjaIndikatif;
